import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useContext } from "react";
import Tab from "../../../../contexts/Tab/Tab";
import "./LinkItem.scss";

const LinkItem = ({ text, size, icon, tab, onClick, specials = "" }) => {
  const { currentTab, setCurrentTab } = useContext(Tab);

  return (
    <div
      className={`lb-container${
        tab === currentTab ? " active" : ""
      }${specials}`}
      onClick={onClick ? onClick : () => setCurrentTab(tab)}
    >
      <>
        <div className="lb-icon">
          {icon && <FontAwesomeIcon icon={icon} size={"lg"} title={text} />}
        </div>
        <div className="lb-text">{text}</div>
      </>
    </div>
  );
};

export default LinkItem;
