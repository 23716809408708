import { useEffect, useRef } from "react";
import { useState } from "react";
import "./Dropdown.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSortDown, faTimes } from "@fortawesome/free-solid-svg-icons";

const Dropdown = ({
  options,
  value,
  onSelect,
  onDisplay = (i) => i,
  placeholder = "",
  onClear,
}) => {
  const [open, setOpen] = useState(false);
  const nodeRef = useRef();

  const handleSelect = (v) => {
    onSelect(v);
    setOpen(false);
  };

  // Handle clicks outside of the portal-root and dropdown
  const handleClickOutside = (e) => {
    if (isDropdownRelated(e, nodeRef)) return;
    setOpen(false);
  };

  const isDropdownRelated = (e, nodeRef) => {
    if (nodeRef.current === null) return true; // If no target do nothing
    if (nodeRef.current.contains(e.target)) return true; // If target contains this dropdown, do nothing
    if (nodeRef.current === e.target) return true;
    // if (document.getElementById("portal-root").contains(e.target)) return true; // If target is contained within portal-root, do nothing
    return false;
  };

  const renderOptions = options.map((opt, i) => (
    <button
      key={i}
      type="button"
      onClick={() => handleSelect(opt)}
      className="dd-button"
    >
      {onDisplay(opt)}
    </button>
  ));

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside, {
      capture: true,
    });
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
    //eslint-disable-next-line
  }, []);

  const handleClear = (e) => {
    e.preventDefault();
    e.stopPropagation();
    onClear();
  };

  return (
    <div ref={nodeRef} className="dd-main">
      <button
        type={"button"}
        onClick={() => setOpen(!open)}
        className="dd-current"
      >
        <div className={"dd-text"}>
          {value || <span className="dd-placeholder">{placeholder}</span>}
        </div>
        {onClear && value && (
          <FontAwesomeIcon
            icon={faTimes}
            className={"clear"}
            onClick={handleClear}
          />
        )}
        <FontAwesomeIcon icon={faSortDown} />
      </button>
      {open && (
        <div className="dd-content">
          <div className="dd-options">{renderOptions}</div>
        </div>
      )}
    </div>
  );
};

export default Dropdown;
