import PropTypes from "prop-types";
import CollapsibleSection from "../../../../components/CollapsibleSection/CollapsibleSection";
import RollItem from "./RollItem/RollItem";

const RollSection = ({ title, items }) => {
  return (
    <CollapsibleSection key={title} title={title}>
      {items
        .sort((a, b) => new Date(a[2]) - new Date(b[2]))
        .map((item) => (
          <RollItem
            key={item[4]}
            id={item[4]}
            name={item[1]}
            start={item[2]}
            end={item[3]}
          />
        ))}
    </CollapsibleSection>
  );
};

RollSection.propTypes = {
  title: PropTypes.string,
  items: PropTypes.arrayOf(PropTypes.string),
};

export default RollSection;
