import PropTypes from "prop-types";
import { useEffect } from "react";
import { useState } from "react";
import LoadingSpinner from "../../components/LoadingSpinner/LoadingSpinner";
import TextInput from "../../components/TextInput/TextInput";
import "./Login.scss";

const Login = ({ onLoginSuccess, error, setError }) => {
  const [username, setUsername] = useState("");
  const [pass, setPass] = useState("");
  const [loading, setLoading] = useState(false);
  const [version, setVersion] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setLoading(true);
    fetch(`${process.env.REACT_APP_API_URL}/user/login`, {
      method: "POST",
      headers: {
        Accept: "application/json, text/plain, */*",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        username: username,
        password: pass,
      }),
    })
      .then((res) => res.json())
      .then(onLoginSuccess)
      .then(() => setLoading(false));
  };

  useEffect(() => {
    fetch(`${process.env.REACT_APP_API_URL}/version`)
      .then((res) => res.text())
      .then((res) => setVersion(res));
  }, []);

  return (
    <div className="login">
      <h1>Login Page</h1>
      {!!error && (
        <p
          style={{
            textAlign: "center",
            color: "red",
            fontWeight: "bolder",
            cursor: "pointer",
          }}
          onClick={() => setError({})}
        >
          {error.error}
        </p>
      )}
      <form onSubmit={handleSubmit}>
        <TextInput value={username} setValue={setUsername} label={"Username"} />
        <TextInput
          value={pass}
          setValue={setPass}
          type={"password"}
          label={"Password"}
        />
        <button type="submit" disabled={loading}>
          {loading ? <LoadingSpinner /> : "Login"}
        </button>
      </form>
      <p className="mistake-text">Here by mistake?</p>
      <a className="mistake-link" href="https://lostriders.co.uk">
        Go to Lostriders
      </a>
      <div className="bottom">
        {process.env.REACT_APP_ENVIRONMENT !== "development" && (
          <div>
            {process.env.REACT_APP_VERSION} &sdot;{" "}
            {version || process.env.REACT_APP_ENVIRONMENT}
          </div>
        )}
        <div>Lostriders © {new Date().getFullYear()}</div>
      </div>
    </div>
  );
};

Login.propTypes = {
  onLoginSuccess: PropTypes.func.isRequired,
};

export default Login;
