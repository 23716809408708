import { faCheck, faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useContext } from "react";
import Tab from "../../../../contexts/Tab/Tab";
import "./NewsletterRow.scss";

const NewsletterRow = ({ newsletter, year }) => {
  const { setCurrentTab } = useContext(Tab);
  return (
    <div className="newsletter-row">
      <div className="nr-month">{newsletter.month}</div>
      <div
        className={`nr-avail${
          newsletter.present ? " available" : " not-available"
        }`}
      >
        <FontAwesomeIcon
          icon={newsletter.present ? faCheck : faTimes}
          title={newsletter.present ? "PDF Present" : "No PDF Available"}
        />
      </div>
      <div className="nr-action">
        {newsletter.present ? (
          <button>Remove</button>
        ) : (
          <button
            onClick={() =>
              setCurrentTab(`newsletters/upload/${newsletter.month}/${year}`)
            }
          >
            Add
          </button>
        )}
      </div>
    </div>
  );
};

export default NewsletterRow;
