import { createRef, useContext, useEffect, useState } from "react";
import Contact from "../../../api/controllers/Contact/Contact";
import FileInput from "../../../components/FileInput/FileInput";
import LoadingSpinner from "../../../components/LoadingSpinner/LoadingSpinner";
import Message from "../../../components/Message/Message";
import PositionSelect from "../../../components/PositionSelect/PositionSelect";
import TextInput from "../../../components/TextInput/TextInput";
import Tab from "../../../contexts/Tab/Tab";

const ContactsEdit = ({ id }) => {
  const { setCurrentTab } = useContext(Tab);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState();
  const [data, setData] = useState([]);
  const [title, setTitle] = useState("");
  const [name, setName] = useState("");
  const [num1, setNum1] = useState("");
  const [num2, setNum2] = useState("");
  const [email, setEmail] = useState("");
  const [position, setPosition] = useState("");
  const fileRef = createRef();

  const handleSubmit = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setLoading(true);
    const imgToUpload = fileRef.current.files[0];
    if (!imgToUpload) {
      Contact.update(id, title, name, num1, num2, email, position)
        .then(
          () => setCurrentTab("contacts"),
          (err) => setError(err)
        )
        .finally(() => setLoading(false));
    } else {
      Contact.uploadImage(imgToUpload).then(
        () => {
          Contact.update(
            id,
            title,
            name,
            num1,
            num2,
            email,
            position,
            imgToUpload.name
          )
            .then(
              () => setCurrentTab("contacts"),
              (err) => setError(err)
            )
            .finally(() => setLoading(false));
        },
        (err) => {
          setError(err);
          setLoading(false);
        }
      );
    }
  };

  const setAll = (foundContact) => {
    setTitle(foundContact.title);
    setName(foundContact.name);
    setNum1(foundContact.num1);
    setNum2(foundContact.num2);
    setEmail(foundContact.email);
  };

  useEffect(() => {
    fetch(`${process.env.REACT_APP_API_URL}/contact/`)
      .then((response) => response.json())
      .then((response) => {
        const curr = response.find((item) => item._id === id);
        setAll(curr);
        setData(
          response
            .sort((a, b) => a.position - b.position)
            .filter((i) => i._id !== id)
        );
        setPosition(curr.position);
      })
      .then(() => setLoading(false));
  }, [id]);

  return (
    <div className="new-event">
      <div>
        <button className="inline" onClick={() => setCurrentTab("contacts")}>
          Contacts
        </button>{" "}
        &gt; <button className="inline">Edit</button>
      </div>
      <h2>Edit Contact</h2>
      {error && (
        <Message
          type="error"
          message={error.error}
          onClick={() => setError()}
        />
      )}
      <form onSubmit={handleSubmit}>
        <TextInput value={title} setValue={setTitle} label={"Title"} />
        <TextInput value={name} setValue={setName} label={"Name"} />
        <TextInput value={num1} setValue={setNum1} label={"Number 1"} />
        <TextInput value={num2} setValue={setNum2} label={"Number 2"} />
        <TextInput value={email} setValue={setEmail} label={"Email"} />
        <PositionSelect
          position={position}
          setPosition={setPosition}
          items={data}
          label={"Position (top = 0)"}
        />
        <FileInput
          ref={fileRef}
          label={"Photo"}
          info={"Only JPG / jpg files can be uploaded"}
        />
        <button type="submit" disabled={loading}>
          {loading ? <LoadingSpinner /> : "Create"}
        </button>
      </form>
    </div>
  );
};

export default ContactsEdit;
