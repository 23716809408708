import { useContext } from "react";
import Tab from "../../../../../contexts/Tab/Tab";
import "./RollItem.scss";

const RollItem = ({ id, name, start, end }) => {
  const { setCurrentTab } = useContext(Tab);

  const formatToDate = (dateString, options = { endDate: false }) => {
    const dateObj = new Date(dateString);

    if (!options.endDate)
      return new Intl.DateTimeFormat("en-GB").format(dateObj);

    const today = new Date(Date.now());

    if (
      dateObj.getFullYear() === today.getFullYear() &&
      dateObj.getMonth() === today.getMonth() &&
      dateObj.getDate() === today.getDate()
    )
      return "Present";

    return new Intl.DateTimeFormat("en-GB").format(dateObj);
  };

  return (
    <div className="roll-item">
      <div className="ri-name">{name}</div>
      <div className="ri-dates">
        {formatToDate(start)} - {formatToDate(end, { endDate: true })}
      </div>
      <div className="ri-action">
        <button onClick={() => setCurrentTab(`roll-of-honour/item/edit/${id}`)}>
          Edit
        </button>
      </div>
    </div>
  );
};
export default RollItem;
