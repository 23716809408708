import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { SAVE_TOKEN } from "../actions/types";
import Login from "../pages/Login/Login";
import Main from "./Main/Main";

function Provided() {
  const [user, setUser] = useState(JSON.parse(localStorage.getItem("user")));
  const { token } = useSelector((state) => state.userReducer);
  const [loginErr, setLoginErr] = useState({});
  const dispatch = useDispatch();

  const handleLoginSuccess = (res) => {
    if (res.error) return setLoginErr(res);
    dispatch({ type: SAVE_TOKEN, payload: res.accessToken });
    localStorage.setItem("token", res.accessToken);
    setUser(res.user);
    localStorage.setItem("user", JSON.stringify(res.user));
  };

  if (!token)
    return (
      <Login
        onLoginSuccess={handleLoginSuccess}
        error={loginErr}
        setError={setLoginErr}
      />
    );
  return <Main user={user} />;
}

export default Provided;
