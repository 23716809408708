import { formatDate } from "../../utils/formatDate";
import "./EventPoster.scss";

const EventPoster = ({ name, eventdate }) => (
  <div className="event-poster">
    <div className="name">{name}</div>
    <div className="date">{formatDate(eventdate)}</div>
    <div className="actions">
      <button>Delete</button>
    </div>
  </div>
);

export default EventPoster;
