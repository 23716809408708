import { faCaretLeft, faCaretRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect } from "react";
import "./FeaturedModal.scss";

const FeaturedModal = ({ photos, idx, setIdx, directory }) => {
  const handleClick = (direction) => {
    if (direction === "left") {
      if (idx === 0) return;
      setIdx(idx - 1);
    }
    if (direction === "right") {
      if (idx === photos.length - 1) return;
      setIdx(idx + 1);
    }
  };

  const handleKey = (e) => {
    if (e.key === "ArrowRight") handleClick("right");
    if (e.key === "ArrowLeft") handleClick("left");
  };

  useEffect(() => {
    document.addEventListener("keydown", handleKey);
    return () => document.removeEventListener("keydown", handleKey);
    // eslint-disable-next-line
  }, [idx]);

  return (
    <div className="featured-modal">
      <div
        className={`arrow arrow-left ${idx === 0 ? "disabled" : ""}`}
        onClick={() => handleClick("left")}
      >
        <FontAwesomeIcon icon={faCaretLeft} size={"5x"} disabled={idx === 0} />
      </div>
      <div className="fm-image-info">
        Image {idx + 1} of {photos.length}
      </div>
      <img
        src={`https://lostriders.co.uk/${directory}/${
          photos[idx].url || photos[idx]
        }`}
        alt={photos[idx].url}
        className={"fm-image"}
        loading="lazy"
      />
      <div
        className={`arrow arrow-right ${
          idx === photos.length - 1 ? "disabled" : ""
        }`}
        onClick={() => handleClick("right")}
      >
        <FontAwesomeIcon icon={faCaretRight} size={"5x"} />
      </div>
    </div>
  );
};

export default FeaturedModal;
