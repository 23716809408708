import { EXTERNAL_ERROR } from "../../actions/types";
import store from "../../store";

const externalUpload = (verb, path, data) => {
  const { token } = store.getState().userReducer;

  return new Promise((resolve, reject) => {
    const xhr = new XMLHttpRequest();

    let url = [process.env.REACT_APP_UPLOAD_URL, path].join("/");

    if (data instanceof URLSearchParams) url = [url, data].join("?");

    xhr.open(verb, url, true); //true means request is asynchronous

    if (token) xhr.setRequestHeader("Authorization", `Bearer ${token}`);

    if (!data || data instanceof URLSearchParams) {
      xhr.send();
    } else if (data instanceof FormData) {
      xhr.send(data);
    } else {
      xhr.setRequestHeader("Content-Type", "application/json");
      xhr.send(JSON.stringify(data));
    }

    xhr.onload = () => {
      const json = xhr.responseText;

      if (xhr.status === 401) {
        store.dispatch({
          type: EXTERNAL_ERROR,
          payload: "Your session has expired. Please sign in again.",
        });
        reject();
      }

      try {
        return resolve(JSON.parse(json)); // this could break
      } catch (err) {
        return reject({ error: "Result in bad format" });
      }
    };
  });
};

export default externalUpload;
