import { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { EXTERNAL_ERROR } from "../../../actions/types";
import LoadingSpinner from "../../../components/LoadingSpinner/LoadingSpinner";
import Tab from "../../../contexts/Tab/Tab";
import "./AdminIndex.scss";
import UserInfo from "./subComponents/UserInfo/UserInfo";

const AdminIndex = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const { setCurrentTab } = useContext(Tab);
  const { token } = useSelector((state) => state.userReducer);
  const dispatch = useDispatch();

  const getData = async () => {
    try {
      const resp = await fetch(`${process.env.REACT_APP_API_URL}/user/`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      if (!resp.ok) throw Error("no");
      setData(await resp.json());
      setLoading(false);
    } catch (err) {
      dispatch({ type: EXTERNAL_ERROR, payload: "Token Expired" });
    }
  };

  useEffect(() => {
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token]);

  return (
    <div className="admin-index">
      <div>
        <button className="inline">Admin</button>
      </div>
      <div className="top-section">
        <h2>Admin</h2>
        <button onClick={() => setCurrentTab("admin/user/new")}>
          Add new user
        </button>
      </div>
      <div className="content">
        {loading && (
          <div className="spin">
            <LoadingSpinner size="5x" />
          </div>
        )}
        {data.map((user) => (
          <UserInfo
            key={user._id}
            user={user}
            token={token}
            refreshData={getData}
          />
        ))}
      </div>
    </div>
  );
};

export default AdminIndex;
