import { useState } from "react";
import { useContext } from "react";
import { useSelector } from "react-redux";
import LoadingSpinner from "../../../components/LoadingSpinner/LoadingSpinner";
import Message from "../../../components/Message/Message";
import TextInput from "../../../components/TextInput/TextInput";
import Tab from "../../../contexts/Tab/Tab";
import "./AdminNewUser.scss";

const AdminNewUser = () => {
  const { setCurrentTab } = useContext(Tab);
  const { token } = useSelector((state) => state.userReducer);
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState("");
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [passwordConfirm, setPasswordConfirm] = useState("");
  const [error, setError] = useState();

  const handleSuccess = (res) => {
    if (res.error || res.errors) return setError(res);
    setCurrentTab("admin");
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setLoading(true);
    if (!password) {
      setError({ error: "Password is required" });
      return setLoading(false);
    }
    if (password !== passwordConfirm) {
      setError({ error: "Passwords must match" });
      return setLoading(false);
    }
    fetch(`${process.env.REACT_APP_API_URL}/user`, {
      method: "POST",
      headers: {
        Accept: "application/json, text/plain, */*",
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        email: email,
        username: username,
        password: password,
      }),
    })
      .then((res) => res.json())
      .then(handleSuccess)
      .then(() => setLoading(false));
  };

  return (
    <div className="admin-new-user">
      <div>
        <button className="inline" onClick={() => setCurrentTab("admin")}>
          Admin
        </button>{" "}
        &gt; <button className="inline">New User</button>
      </div>
      <h2>Create New User</h2>
      {error && (
        <Message
          type="error"
          message={error.error || error.errors}
          onClick={() => setError()}
        />
      )}
      <form onSubmit={handleSubmit}>
        <TextInput
          value={email}
          setValue={setEmail}
          label={"Email"}
          type="email"
        />
        <TextInput value={username} setValue={setUsername} label={"Username"} />
        <TextInput
          value={password}
          setValue={setPassword}
          label={"Password"}
          type={"password"}
        />
        <TextInput
          value={passwordConfirm}
          setValue={setPasswordConfirm}
          label={"Confirm Password"}
          type={"password"}
        />
        <button type="submit" disabled={loading}>
          {loading ? <LoadingSpinner /> : "Create"}
        </button>
      </form>
    </div>
  );
};

export default AdminNewUser;
