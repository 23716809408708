import { useContext } from "react";
import Toggle from "../../../components/Toggle/Toggle";
import Theme from "../../../contexts/Theme/Theme";
import "./SettingsIndex.scss";

const SettingsIndex = () => {
  const { theme, setTheme } = useContext(Theme);

  const toggleTheme = () => {
    if (theme === "light") return setTheme("dark");

    setTheme("light");
  };
  return (
    <div className="settings">
      <div>
        <button className="inline">Settings</button>
      </div>
      <div className="top-section">
        <h2>Settings</h2>
      </div>
      <div className="content">
        <Toggle
          label={"Theme"}
          toggled={theme === "dark"}
          toggle={toggleTheme}
          optionToggled={"Dark"}
          optionOff={"Light"}
        />
      </div>
    </div>
  );
};

export default SettingsIndex;
