import { useEffect } from "react";
import { useState } from "react";
import { useContext } from "react";
import Chart from "react-google-charts";
import RollHonour from "../../../api/controllers/RollHonour/RollHonour";
import LoadingSpinner from "../../../components/LoadingSpinner/LoadingSpinner";
import Tab from "../../../contexts/Tab/Tab";
import "./RollHonourFormatted.scss";

const RollHonourFormatted = () => {
  const { setCurrentTab } = useContext(Tab);
  const [roll, setRoll] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(true);
    RollHonour.all()
      .then((res) => {
        setRoll([
          [
            { type: "string", id: "Title" },
            { type: "string", id: "Name" },
            { type: "date", id: "Start" },
            { type: "date", id: "End" },
          ],
          ...res.map((i) => [i[0], i[1], new Date(i[2]), new Date(i[3])]),
        ]);
      })
      .finally(() => setLoading(false));
  }, []);

  return (
    <div className="roll-honour">
      <div>
        <button
          className="inline"
          onClick={() => setCurrentTab("roll-of-honour")}
        >
          Roll of Honour
        </button>{" "}
        &gt; <button className="inline">Formatted</button>
      </div>
      <div className="top-section">
        <h2>Roll of Honour Formatted</h2>
      </div>
      <div className="content">
        {loading && (
          <div className="spin">
            <LoadingSpinner size="5x" />
          </div>
        )}
        {roll && (
          <Chart
            chartType="Timeline"
            data={roll}
            width="100%"
            height="700px"
            options={{
              colors: ["#595959", "#2c2c2c", "#6d6d6d"],
              backgroundColor: "#434343",
              tooltip: { isHtml: false },
            }}
          />
        )}
      </div>
    </div>
  );
};

RollHonourFormatted.propTypes = {};

export default RollHonourFormatted;
