import { useContext, useEffect, useState } from "react";
import EventPoster from "../../../components/EventPoster/EventPoster";
import LoadingSpinner from "../../../components/LoadingSpinner/LoadingSpinner";
import Tab from "../../../contexts/Tab/Tab";
import "./EventsIndex.scss";

const EventsIndex = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const { setCurrentTab } = useContext(Tab);

  useEffect(() => {
    fetch(`${process.env.REACT_APP_API_URL}/poster/`)
      .then((response) => response.json())
      .then((response) =>
        setData(response.sort((a, b) => a.eventdate > b.eventdate))
      )
      .then(() => setLoading(false));
  }, []);

  return (
    <div className="events">
      <div>
        <button className="inline">Event Posters</button>
      </div>
      <div className="top-section">
        <h2>Event Posters</h2>
        <button onClick={() => setCurrentTab("events/new")}>
          Add new poster
        </button>
      </div>
      <div className="content">
        <div className="events-table">
          <div className="table-headers">
            <div className="name">Name</div>
            <div className="date">Event Date</div>
            <div className="actions"></div>
          </div>
          <div className="table-content">
            {loading && (
              <div className="spin">
                <LoadingSpinner size="5x" />
              </div>
            )}
            {data.map(({ _id, name, eventdate }) => (
              <EventPoster key={_id} name={name} eventdate={eventdate} />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default EventsIndex;
