import { useContext } from "react";
import Tab from "../../contexts/Tab/Tab";
import CollapsibleSection from "../CollapsibleSection/CollapsibleSection";
import ArchiveAlbum from "./subComponents/ArchiveAlbum/ArchiveAlbum";

const ArchiveYear = ({ year }) => {
  const { setCurrentTab } = useContext(Tab);

  const ArchiveButton = (
    <button
      onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();
        setCurrentTab(`photos/album/new/${year.year}`);
      }}
    >
      Add new Album
    </button>
  );

  return (
    <CollapsibleSection title={year.year} extraComponent={ArchiveButton}>
      {year.photos.map((album) => (
        <ArchiveAlbum album={album} />
      ))}
    </CollapsibleSection>
  );
};

export default ArchiveYear;
