import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useContext } from "react";
import { useEffect } from "react";
import { useState } from "react";
import RollHonour from "../../../api/controllers/RollHonour/RollHonour";
import Dropdown from "../../../components/Dropdown/Dropdown";
import LoadingSpinner from "../../../components/LoadingSpinner/LoadingSpinner";
import Message from "../../../components/Message/Message";
import TextInput from "../../../components/TextInput/TextInput";
import Tab from "../../../contexts/Tab/Tab";
import "./RollHonourNew.scss";

const RollHonourNew = () => {
  const { setCurrentTab } = useContext(Tab);
  const [name, setName] = useState("");
  const [start, setStart] = useState("");
  const [end, setEnd] = useState("");
  const [error, setError] = useState();
  const [selectedTitle, setSelectedTitle] = useState("");
  const [titles, setTitles] = useState([]);
  const [loading, setLoading] = useState(false);
  const [newTitle, setNewTitle] = useState("");

  useEffect(() => {
    RollHonour.titles().then((res) => {
      setTitles(res);
      setSelectedTitle(res[0]);
    });
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setLoading(true);
    RollHonour.new(selectedTitle, name, start, end).then(
      (_) => setCurrentTab("roll-of-honour"),
      (rej) => {
        setError(rej);
        setLoading(false);
      }
    );
  };

  const addTitle = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setTitles([...titles, newTitle]);
    setSelectedTitle(newTitle);
    setNewTitle("");
  };

  return (
    <div className="new-roll-honour">
      <div>
        <button
          className="inline"
          onClick={() => setCurrentTab("roll-of-honour")}
        >
          Roll of Honour
        </button>{" "}
        &gt; <button className="inline">New</button>
      </div>
      <div className="top-section">
        <h2>New Honour Item</h2>
      </div>
      <div className="content">
        {error && (
          <Message
            type="error"
            message={error.error}
            onClick={() => setError()}
          />
        )}
        <form onSubmit={handleSubmit}>
          <div>
            <Dropdown
              value={selectedTitle}
              options={titles}
              onDisplay={(i) => i}
              onSelect={setSelectedTitle}
            />
            <div className="new-title">
              <input
                value={newTitle}
                placeholder={"Add New Title"}
                onChange={(e) => setNewTitle(e.target.value)}
              />
              <button type="button" className="fr-add" onClick={addTitle}>
                <FontAwesomeIcon icon={faPlus} />
              </button>
            </div>
          </div>
          <TextInput value={name} label={"Name"} setValue={setName} required />
          <TextInput
            value={start}
            label={"Start"}
            type={"date"}
            setValue={setStart}
            required
          />
          <TextInput
            value={end}
            label={"End"}
            type={"date"}
            setValue={setEnd}
          />
          <button type="submit" disabled={loading}>
            {loading ? <LoadingSpinner /> : "Add"}
          </button>
        </form>
      </div>
    </div>
  );
};

export default RollHonourNew;
