import NewsletterRow from "./subComponents/NewsletterRow/NewsletterRow";
import "./NewsletterYears.scss";

const NewsletterYears = ({ year, newsletters }) => (
  <div className="newsletter-year">
    <div className="ny-year">{year}</div>
    <div>
      {newsletters.map((newsletter) => (
        <NewsletterRow
          key={newsletter._id}
          newsletter={newsletter}
          year={year}
        />
      ))}
    </div>
  </div>
);

export default NewsletterYears;
