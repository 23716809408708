import { faPlus, faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { createRef, useContext, useState } from "react";
import Shop from "../../../../api/controllers/Shop/Shop";
import FileInput from "../../../../components/FileInput/FileInput";
import LoadingSpinner from "../../../../components/LoadingSpinner/LoadingSpinner";
import Message from "../../../../components/Message/Message";
import TagsInput from "../../../../components/TagsInput/TagsInput";
import TextAreaInput from "../../../../components/TextAreaInput/TextAreaInput";
import TextInput from "../../../../components/TextInput/TextInput";
import Tab from "../../../../contexts/Tab/Tab";
import "./ShopItemNew.scss";

const ShopItemNew = () => {
  const { setCurrentTab } = useContext(Tab);
  const [loading, setLoading] = useState(false);
  const [name, setName] = useState("");
  const [price, setPrice] = useState("");
  const [description, setDescription] = useState("");
  const [error, setError] = useState("");
  const [sizes, setSizes] = useState([]);
  const [sections, setSections] = useState([]);
  const fileRef = createRef();

  const handleSubmit = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setLoading(true);
    const images = fileRef.current.files;

    if (images.length === 0) {
      Shop.createItem(name, [], price, sizes, sections, description)
        .then(
          () => setCurrentTab("shop"),
          (err) => setError(err)
        )
        .finally(() => setLoading(false));
    } else {
      Shop.uploadImages(images).then(
        () => {
          Shop.createItem(
            name,
            [...images].map((image) => image.name),
            price,
            sizes,
            sections,
            description
          )
            .then(
              () => setCurrentTab("shop"),
              (err) => setError(err)
            )
            .finally(() => setLoading(false));
        },
        (err) => {
          setError(err);
          setLoading(false);
        }
      );
    }
  };

  const handleSizeChange = (idx, value) => {
    setSizes(sizes.map((v, i) => (i === idx ? value : v)));
  };

  const removeRow = (idx) => {
    setSizes(sizes.filter((_, i) => i !== idx));
  };

  const addRow = () => setSizes([...sizes, ""]);

  return (
    <div className="shop-new-item">
      <div>
        <button className="inline" onClick={() => setCurrentTab("shop")}>
          Shop
        </button>{" "}
        &gt; <button className="inline">New Item</button>
      </div>
      <h2>New Item</h2>
      {error && (
        <p
          style={{
            textAlign: "center",
            color: "red",
            fontWeight: "bolder",
            cursor: "pointer",
          }}
          onClick={() => setError({})}
        >
          {error}
        </p>
      )}
      <form onSubmit={handleSubmit}>
        <TextInput value={name} setValue={setName} label={"Name"} />
        <TextInput
          type={"number"}
          value={price}
          setValue={setPrice}
          label={"Price"}
          min={0}
          step={0.01}
        />
        <p>Sizes / Types</p>
        {/* TODO: Change to reusable component */}
        {sizes.map((size, idx) => (
          <div className="featured-row">
            <TextInput
              key={idx}
              value={size}
              setValue={(val) => handleSizeChange(idx, val)}
            />
            <button
              className="fr-remove"
              type="button"
              onClick={() => removeRow(idx)}
            >
              <FontAwesomeIcon icon={faTimes} />
            </button>
          </div>
        ))}
        <div className="featured-row">
          <button className="fr-add" type="button" onClick={addRow}>
            <FontAwesomeIcon icon={faPlus} /> Add Row
          </button>
        </div>
        <TextAreaInput
          value={description}
          setValue={setDescription}
          label={"Description"}
        />
        <TagsInput tags={sections} setTags={setSections} label={"Sections"} />
        <FileInput
          ref={fileRef}
          label={"Select Images:"}
          info={
            "Blank no change\nOverwrites all\n[CTRL + click Selects multiple]"
          }
          multiple
        />
        <button type="submit" disabled={loading}>
          {loading ? <LoadingSpinner /> : "Create"}
        </button>
      </form>
    </div>
  );
};

export default ShopItemNew;
