import httpRequest from "../../httpRequest/httpRequest";

class RollHonour {
  static async all(id = false) {
    return httpRequest("GET", id ? "v2/roll?includeId=1" : "v2/roll");
  }

  static async new(title, name, startDate, endDate) {
    return httpRequest("POST", "v2/roll", { title, name, startDate, endDate });
  }

  static async show(id) {
    return httpRequest("GET", `v2/roll/${id}`);
  }

  static async update(id, title, name, startDate, endDate) {
    return httpRequest("PUT", `v2/roll/${id}`, {
      title,
      name,
      startDate,
      endDate,
    });
  }

  static async titles() {
    return httpRequest("GET", "v2/roll/titles");
  }
}

export default RollHonour;
