import { Editor } from "@tinymce/tinymce-react";
import { forwardRef, useEffect, useContext } from "react";
import Theme from "../../contexts/Theme/Theme";
import PropTypes from "prop-types";

const RichText = forwardRef(({ bgCol, value = "" }, ref) => {
  const { theme } = useContext(Theme);

  useEffect(() => {
    if (!ref.current || !ref.current.getBody()) return;
    ref.current.getBody().style.backgroundColor = bgCol;
  }, [bgCol]);

  return (
    <Editor
      apiKey="8ygikbrsdmlac0ofzctfav353m18mlweissjanuge7jmhd1f"
      onInit={(evt, editor) => (ref.current = editor)}
      initialValue={value}
      init={{
        selector: "textarea#full-featured",
        plugins:
          "print preview importcss searchreplace autolink autosave save directionality visualblocks visualchars fullscreen image link media template codesample table charmap hr pagebreak nonbreaking anchor toc insertdatetime advlist lists wordcount textpattern noneditable help charmap quickbars emoticons",
        mobile: {
          plugins:
            "print preview importcss tinydrive searchreplace autolink autosave save directionality visualblocks visualchars fullscreen image link media template codesample table charmap hr pagebreak nonbreaking anchor toc insertdatetime advlist lists wordcount imagetools textpattern noneditable help charmap quickbars emoticons",
        },
        toolbar:
          "undo redo | bold italic underline strikethrough | alignleft aligncenter alignright alignjustify | outdent indent |  numlist bullist | forecolor backcolor removeformat |  charmap emoticons | image media pageembed link codesample | fullscreen",
        image_advtab: true,
        importcss_append: true,
        height: 500,
        image_caption: true,
        quickbars_selection_toolbar:
          "bold italic | quicklink h2 h3 blockquote quickimage quicktable",
        noneditable_noneditable_class: "mceNonEditable",
        toolbar_mode: "sliding",
        tinycomments_mode: "embedded",
        contextmenu: "link image imagetools table configurepermanentpen",
        a11y_advanced_options: true,
        skin_url:
          theme === "light"
            ? "https://lostriders.co.uk/styles/tinymce/light"
            : "https://lostriders.co.uk/styles/tinymce/dark",
        content_css: "dark",
        content_style: `body {background-color: ${bgCol};}`,
      }}
    />
  );
});

RichText.displayName = "RichText";

RichText.propTypes = {
  bgCol: PropTypes.string,
  value: PropTypes.string,
};

export default RichText;
