import { useState } from "react";
import { useEffect } from "react";
import { useContext } from "react";
import { useSelector } from "react-redux";
import LoadingSpinner from "../../../components/LoadingSpinner/LoadingSpinner";
import Message from "../../../components/Message/Message";
import TextInput from "../../../components/TextInput/TextInput";
import Tab from "../../../contexts/Tab/Tab";
import "./AdminEditUser.scss";

const AdminEditUser = ({ id }) => {
  const { setCurrentTab } = useContext(Tab);
  const { token } = useSelector((state) => state.userReducer);
  const [dataLoading, setDataLoading] = useState(true);
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState("");
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [passwordConfirm, setPasswordConfirm] = useState("");
  const [error, setError] = useState();

  useEffect(() => {
    fetch(`${process.env.REACT_APP_API_URL}/user/${id}`, {
      method: "GET",
      headers: {
        Accept: "application/json, text/plain, */*",
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((res) => {
        setUsername(res.username);
        setEmail(res.email);
      })
      .then(() => setDataLoading(false));
  }, [id, token]);

  const handleSuccess = (res) => {
    if (res.error || res.errors) return setError(res);
    setCurrentTab("admin");
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setLoading(true);
    if (password !== passwordConfirm) {
      setError({ error: "Passwords must match" });
      return setLoading(false);
    }
    fetch(`${process.env.REACT_APP_API_URL}/user/${id}`, {
      method: "PUT",
      headers: {
        Accept: "application/json, text/plain, */*",
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        email: email,
        username: username,
        password: password && password,
      }),
    })
      .then((res) => res.json())
      .then(handleSuccess)
      .then(() => setLoading(false));
  };

  return (
    <div className="admin-edit-user">
      <div>
        <button className="inline" onClick={() => setCurrentTab("admin")}>
          Admin
        </button>{" "}
        &gt; <button className="inline">Edit User: {id}</button>
      </div>
      <h2>Edit User</h2>
      {error && (
        <Message
          type="error"
          message={error.error || error.errors}
          onClick={() => setError()}
        />
      )}
      {dataLoading ? (
        <div className="spin">
          <LoadingSpinner size="5x" />
        </div>
      ) : (
        <form onSubmit={handleSubmit}>
          <TextInput
            value={email}
            setValue={setEmail}
            label={"Email"}
            type="email"
          />
          <TextInput
            value={username}
            setValue={setUsername}
            label={"Username"}
          />
          <TextInput
            value={password}
            setValue={setPassword}
            label={"Password"}
            type={"password"}
          />
          <TextInput
            value={passwordConfirm}
            setValue={setPasswordConfirm}
            label={"Confirm Password"}
            type={"password"}
          />
          <button type="submit" disabled={loading}>
            {loading ? <LoadingSpinner /> : "Update"}
          </button>
        </form>
      )}
    </div>
  );
};

export default AdminEditUser;
