import { useEffect, useState } from "react";
import { Provider } from "react-redux";
import Theme from "../contexts/Theme/Theme";
import store from "../store";
import Provided from "./Provided";

function App() {
  const [theme, setTheme] = useState(localStorage.getItem("theme"));

  useEffect(() => {
    localStorage.setItem("theme", theme);
    document.getElementsByTagName("html")[0].dataset.theme = theme;
  }, [theme]);

  return (
    <Provider store={store}>
      <Theme.Provider value={{ theme, setTheme }}>
        <Provided />
      </Theme.Provider>
    </Provider>
  );
}

export default App;
