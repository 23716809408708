import { createRef, useState } from "react";
import { CSSTransition } from "react-transition-group";
import "./CollapsibleSection.scss";

const CollapsibleSection = ({ children, extraComponent, title }) => {
  const [open, setOpen] = useState(false);
  const nodeRef = createRef();

  return (
    <div id={"c-section"} className={"c-section"}>
      <div className="c-section-head" onClick={() => setOpen(!open)}>
        {title}
        {extraComponent}
      </div>
      <CSSTransition
        nodeRef={nodeRef}
        in={open}
        timeout={300}
        classNames="c-section-transition"
      >
        <div className="c-section-body" ref={nodeRef}>
          {children}
        </div>
      </CSSTransition>
    </div>
  );
};

export default CollapsibleSection;
