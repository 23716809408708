import { useContext, useEffect, useState } from "react";
import LoadingSpinner from "../../../components/LoadingSpinner/LoadingSpinner";
import NewsletterYears from "../../../components/NewsletterYears/NewsletterYears";
import Tab from "../../../contexts/Tab/Tab";
import "./NewsletterIndex.scss";

const NewsletterIndex = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const { setCurrentTab } = useContext(Tab);

  useEffect(() => {
    fetch(`${process.env.REACT_APP_API_URL}/newsletter/`)
      .then((response) => response.json())
      .then((response) => setData(response.sort((a, b) => b.year - a.year)))
      .then(() => setLoading(false));
  }, []);

  return (
    <div className="newsletters">
      <div>
        <button className="inline">Newsletters</button>
      </div>
      <div className="top-section">
        <h2>Newsletters</h2>
        <button onClick={() => setCurrentTab("newsletters/year/new")}>
          Add new year
        </button>
      </div>
      <div className="content">
        {loading && (
          <div className="spin">
            <LoadingSpinner size="5x" />
          </div>
        )}
        {data.map(({ _id, year, newsletters }) => (
          <NewsletterYears key={_id} year={year} newsletters={newsletters} />
        ))}
      </div>
    </div>
  );
};

export default NewsletterIndex;
