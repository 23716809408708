import {
  faCalendarDays,
  faCaretLeft,
  faCaretRight,
  faCartShopping,
  faChartBar,
  faCode,
  faDoorOpen,
  faExternalLink,
  faFilePdf,
  faHome,
  faImages,
  faTimeline,
  faUsers,
  faUserShield,
} from "@fortawesome/free-solid-svg-icons";
import { createRef, useEffect, useState, useContext } from "react";
import { useDispatch } from "react-redux";
import { CSSTransition } from "react-transition-group";
import { DELETE_TOKEN } from "../../actions/types";
import Auth from "../../contexts/Auth/Auth";
import "./LeftBar.scss";
import LinkItem from "./subComponents/LinkItem/LinkItem";

const LeftBar = ({ onLogout, admin = false }) => {
  const [shrunk, setShrunk] = useState(false);
  const [shrinkText, setShrinkText] = useState();
  const nodeRef = createRef();
  const dispatch = useDispatch();

  const logout = () => {
    dispatch({ type: DELETE_TOKEN });
  };

  useEffect(() => {
    setTimeout(() => {
      if (shrunk) setShrinkText("Expand Menu");
      else setShrinkText("Shrink Menu");
    }, 290);
  }, [shrunk]);

  return (
    <CSSTransition
      nodeRef={nodeRef}
      in={shrunk}
      timeout={300}
      classNames="left-bar-transition"
    >
      <div id={"left-bar"} className={`left-bar`} ref={nodeRef}>
        <LinkItem
          icon={shrunk ? faCaretRight : faCaretLeft}
          text={shrinkText}
          onClick={() => setShrunk(!shrunk)}
        />
        <LinkItem icon={faHome} text={"Live Site"} tab="live-site" />
        <LinkItem icon={faCode} text={"Dev Site"} tab="dev-site" />
        <LinkItem icon={faFilePdf} text={"Newsletters"} tab="newsletters" />
        <LinkItem icon={faCalendarDays} text={"Events"} tab="events" />
        <LinkItem icon={faUsers} text={"Contacts"} tab="contacts" />
        <LinkItem icon={faImages} text={"Photos"} tab="photos" />
        <LinkItem icon={faCartShopping} text={"Shop"} tab="shop" />
        <LinkItem icon={faChartBar} text={"Home panels"} tab="home-panels" />
        <LinkItem
          icon={faTimeline}
          text={"Roll of Honour"}
          tab="roll-of-honour"
        />
        <LinkItem
          icon={faDoorOpen}
          text={"Logout"}
          onClick={() => logout()}
          specials={" logout"}
        />
        {admin && (
          <LinkItem
            icon={faUserShield}
            text={"Admin"}
            tab="admin"
            specials={" admin"}
          />
        )}
        <LinkItem
          icon={faExternalLink}
          text={"Website Control"}
          onClick={() => window.open("https://www.stackcp.com/", "_blank")}
        />
        <span className="version">
          {process.env.REACT_APP_ENVIRONMENT !== "development" &&
            process.env.REACT_APP_VERSION}
        </span>
      </div>
    </CSSTransition>
  );
};

export default LeftBar;
