import externalUpload from "../../httpRequest/externalUpload";
import httpRequest from "../../httpRequest/httpRequest";

class Shop {
  static uploadImages(images) {
    const formData = new FormData();

    for (const img of images) {
      formData.append("imgs[]", img, img.name);
    }

    return externalUpload("POST", "shopImagesUpload.php", formData);
  }

  static createItem(name, imgs, price, sizes, sections, description) {
    return httpRequest("POST", "v2/shop/item", {
      name,
      imgs,
      price,
      sizes,
      sections,
      description,
    });
  }

  static editItem(id, name, imgs, price, sizes, sections, description) {
    return httpRequest("PUT", `v2/shop/item/${id}`, {
      name,
      imgs,
      price,
      sizes,
      sections,
      description,
    });
  }
}

export default Shop;
