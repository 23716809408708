import { useContext, useEffect, useState } from "react";
import Contact from "../../../components/Contact/Contact";
import LoadingSpinner from "../../../components/LoadingSpinner/LoadingSpinner";
import Tab from "../../../contexts/Tab/Tab";
import "./ContactsIndex.scss";
import ContactApi from "../../../api/controllers/Contact/Contact";

const ContactsIndex = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const { setCurrentTab } = useContext(Tab);

  useEffect(() => {
    fetch(`${process.env.REACT_APP_API_URL}/contact/`)
      .then((response) => response.json())
      .then((response) =>
        setData(response.sort((a, b) => a.position - b.position))
      )
      .then(() => setLoading(false));
  }, []);

  const handleDelete = (e, id) => {
    setLoading(true);
    e.preventDefault();
    e.stopPropagation();
    ContactApi.delete(id)
      .then(() => {
        setData(data.filter((item) => item._id !== id));
      })
      .finally(() => setLoading(false));
  };

  return (
    <div className="contacts">
      <div>
        <button className="inline">Contacts</button>
      </div>
      <div className="top-section">
        <h2>Contacts</h2>
        <button onClick={() => setCurrentTab("contacts/new")}>
          Add new contact
        </button>
      </div>
      <div className="content">
        <div className="contacts-table">
          <div className="table-headers">
            <div className="name">Role</div>
            <div className="name">Name</div>
            <div className="name">Number 1</div>
            <div className="name">Number 2</div>
            <div className="name">Email</div>
            <div className="name">Picture</div>
            <div className="actions"></div>
          </div>
          <div className="table-content">
            {loading && (
              <div className="spin">
                <LoadingSpinner size="5x" />
              </div>
            )}
            {data.map(({ _id, title, name, num1, num2, email, img }) => (
              <Contact
                key={_id}
                id={_id}
                title={title}
                name={name}
                num1={num1}
                num2={num2}
                email={email}
                picture={img}
                handleDelete={handleDelete}
              />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactsIndex;
