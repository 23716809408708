import "./PositionSelect.scss";

const PositionSelect = ({ label, items, position, setPosition }) => {
  const renderOutcomes = () => {
    if (!items) return;
    if (Number(position) === 0) {
      return (
        <>
          <div className="outcome-contact">--START--</div>
          <div className="curr-entity">HERE</div>
          <div className="outcome-contact">
            {items[0]?.title} | {items[0]?.name}
          </div>
        </>
      );
    }

    if (Number(position) === items.length) {
      return (
        <>
          <div className="outcome-contact">
            {items[position - 1]?.title} | {items[position - 1]?.name}
          </div>
          <div className="curr-entity">HERE</div>
          <div className="outcome-contact">--END--</div>
        </>
      );
    }

    return (
      <>
        <div className="outcome-contact">
          {items[position - 1]?.title} |{items[position - 1]?.name}
        </div>
        <div className="curr-entity">HERE</div>
        <div className="outcome-contact">
          {items[position]?.title} |{items[position]?.name}
        </div>
      </>
    );
  };

  return (
    <div className="position-select">
      <p>{label}</p>
      <select value={position} onChange={(e) => setPosition(e.target.value)}>
        {items.map((_, idx) => (
          <option key={idx} value={idx}>
            {idx}
          </option>
        ))}
        <option key={items.length} value={items.length}>
          {items.length}
        </option>
      </select>

      <div className="outcome-show">
        <h4>Surrounding Contacts</h4>
        {renderOutcomes()}
      </div>
    </div>
  );
};

export default PositionSelect;
