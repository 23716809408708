import "./TopBar.scss";
import logo from "../../assets/LR_HIGHRES.png";
import logoInverse from "../../assets/LR_INVERSE.png";
import { useContext } from "react";
import Theme from "../../contexts/Theme/Theme";
import PropTypes from "prop-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCog } from "@fortawesome/free-solid-svg-icons";
import Tab from "../../contexts/Tab/Tab";

const TopBar = ({ user }) => {
  const { theme } = useContext(Theme);
  const { setCurrentTab } = useContext(Tab);

  return (
    <div className="top-bar">
      <div className="section">
        <img
          src={theme === "light" ? logoInverse : logo}
          alt={"logo"}
          className="logo"
        />
        <p className="logo-text">Dev Panel</p>
        {process.env.REACT_APP_ENVIRONMENT !== "production" && (
          <p className="environment-text">
            {process.env.REACT_APP_ENVIRONMENT.toUpperCase()}
          </p>
        )}
      </div>
      <div className="section user-section flex">
        <div className="section user-section">
          <button onClick={() => setCurrentTab("settings")}>
            <FontAwesomeIcon icon={faCog} />
          </button>
        </div>
        <div className="section user-section">
          <p className="user-beginner">Welcome,</p>
          <p className="user-name">{user?.username || ""}</p>
        </div>
      </div>
    </div>
  );
};

TopBar.propTypes = { user: PropTypes.object };

export default TopBar;
