import { useContext, useState } from "react";
import LoadingSpinner from "../../../components/LoadingSpinner/LoadingSpinner";
import TextInput from "../../../components/TextInput/TextInput";
import Tab from "../../../contexts/Tab/Tab";

const PhotosNewAlbum = ({ year }) => {
  const { setCurrentTab } = useContext(Tab);
  const [loading, setLoading] = useState(false);
  const [name, setName] = useState("");
  const [url, setUrl] = useState("");
  const [date, setDate] = useState("");
  const [error, setError] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setLoading(true);
    setLoading(false);
  };

  return (
    <div className="newsletter-new-year">
      <div>
        <button className="inline" onClick={() => setCurrentTab("photos")}>
          Photos
        </button>{" "}
        &gt; <button className="inline">New album: {year}</button>
      </div>
      <h2>New Photo Album</h2>
      {error && (
        <p
          style={{
            textAlign: "center",
            color: "red",
            fontWeight: "bolder",
            cursor: "pointer",
          }}
          onClick={() => setError({})}
        >
          {error}
        </p>
      )}
      <form onSubmit={handleSubmit}>
        <TextInput value={name} setValue={setName} label={"Name"} />
        <TextInput value={url} setValue={setUrl} label={"Album Link"} />
        <TextInput
          type="date"
          value={date}
          setValue={setDate}
          label={"Event Date"}
        />
        <button type="submit" disabled={loading}>
          {loading ? <LoadingSpinner /> : "Create"}
        </button>
      </form>
    </div>
  );
};

export default PhotosNewAlbum;
