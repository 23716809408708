import { useContext } from "react";
import Tab from "../../contexts/Tab/Tab";
import "./Contact.scss";

const Contact = ({
  id,
  title,
  name,
  num1,
  num2,
  email,
  picture,
  handleDelete,
}) => {
  const { setCurrentTab } = useContext(Tab);

  return (
    <div className="contact">
      <div className="name">{title}</div>
      <div className="name">{name}</div>
      <div className="name">{num1}</div>
      <div className="name">{num2}</div>
      <div className="name">{email}</div>
      <div className="name">{picture}</div>
      <div className="actions">
        <button onClick={() => setCurrentTab(`contacts/edit/${id}`)}>
          Edit
        </button>
        <button onClick={(e) => handleDelete(e, id)}>Delete</button>
      </div>
    </div>
  );
};

export default Contact;
