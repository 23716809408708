import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import Tab from "../../contexts/Tab/Tab";
import Content from "../Content/Content";
import LeftBar from "../LeftBar/LeftBar";
import TopBar from "../TopBar/TopBar";
import "./Main.scss";

function humanize(str) {
  return str
    .replace(/^[\s_]+|[\s_]+$/g, "")
    .replace(/^[\s-]+|[\s-]+$/g, "")
    .replace(/[_\s]+/g, " ")
    .replace(/[-\s]+/g, " ")
    .replaceAll("/", " > ")
    .replace(/(\b[a-z](?!\s))/g, function (x) {
      return x.toUpperCase();
    });
}

const Main = ({ user }) => {
  const [currentTab, setCurrentTab] = useState(
    window.location.pathname.substring(1)
  );

  useEffect(() => {
    document.title = `LR Dev v2- ${humanize(currentTab)}`;
    window.history.pushState({}, document.title, `/${currentTab}`);
  }, [currentTab]);

  return (
    <Tab.Provider value={{ currentTab, setCurrentTab }}>
      <div className="page-wrapper">
        <div className="page-wrapper-top">
          <TopBar user={user} />
        </div>
        <div className="page-wrapper-body">
          <div className="page-wrapper-left">
            <LeftBar admin={user.admin} />
          </div>
          <div className="page-wrapper-content">
            <Content user={user} />
          </div>
        </div>
      </div>
    </Tab.Provider>
  );
};

Main.propTypes = {
  user: PropTypes.object,
  setTheme: PropTypes.func.isRequired,
};

export default Main;
