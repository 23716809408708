import "./ConfirmationModal.scss";

const ConfirmationModal = ({
  text,
  onConfirm,
  onCancel,
  confirmText = "Delete",
}) => (
  <div className="confirmation-modal">
    <p>{text}</p>
    <div className="button-row">
      <button className="confirm" onClick={onConfirm}>
        {confirmText}
      </button>
      <button className="cancel" onClick={onCancel}>
        Cancel
      </button>
    </div>
  </div>
);

export default ConfirmationModal;
