import { useState, useContext, useRef } from "react";
import { SketchPicker } from "react-color";
import Home from "../../../api/controllers/Home/Home";
import LoadingSpinner from "../../../components/LoadingSpinner/LoadingSpinner";
import RichText from "../../../components/RichText/RichText";
import TagsInput from "../../../components/TagsInput/TagsInput";
import TextInput from "../../../components/TextInput/TextInput";
import Tab from "../../../contexts/Tab/Tab";
import "./HomePanelsNew.scss";

const HomePanelsNew = () => {
  const { setCurrentTab } = useContext(Tab);
  const [name, setName] = useState("");
  const [classes, setClasses] = useState([]);
  const [loading, setLoading] = useState(false);
  const [bgCol, setBgCol] = useState("#fff");
  const [pickerVisible, setPickerVisible] = useState(false);
  const editorRef = useRef();

  const handleSubmit = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setLoading(true);
    Home.new(name, bgCol, editorRef.current.getContent(), classes).then(() => {
      setLoading(false);
    });
  };

  const handleColorChange = ({ hex }) => setBgCol(hex);
  const onTogglePicker = () => setPickerVisible(!pickerVisible);

  return (
    <div className="home-panels">
      <div>
        <button className="inline" onClick={() => setCurrentTab("home-panels")}>
          Home Panels
        </button>{" "}
        &gt; <button className="inline">New</button>
      </div>
      <div className="top-section">
        <h2>New Home Panel</h2>
        <span>
          To upload/insert images, you need the URL of the image in the format
          https://lostriders.co.uk/content/ITEM Content can be checked on Home
          panel section by clicking &quot;images&quot;
        </span>
      </div>
      <div className="content">
        <form onSubmit={handleSubmit}>
          <TextInput value={name} setValue={setName} label={"Name"} />
          <TagsInput
            label={"Special Classes"}
            tags={classes}
            setTags={setClasses}
          />
          <div style={{ overflow: "visible" }}>
            <p>Background Colour</p>
            <button
              onClick={onTogglePicker}
              style={{
                backgroundColor: bgCol,
                height: 30,
                width: 100,
                borderRadius: 4,
                border: "5px solid var(--lighter-head)",
                margin: "auto",
                display: "block",
              }}
            ></button>

            {pickerVisible && (
              <div
                style={{
                  position: "relative",
                  left: "49%",
                  transform: "translateX(-7px)",
                  zIndex: 100000,
                  height: 0,
                  width: 0,
                  top: 10,
                }}
              >
                <div
                  style={{
                    width: 0,
                    height: 0,
                    borderStyle: "solid",
                    borderWidth: "0 10px 10px",
                    borderColor: "transparent transparent rgb(255, 255, 255)",
                    position: "absolute",
                    top: -10,
                    left: "60%",
                    marginLeft: 10,
                  }}
                ></div>
                <SketchPicker color={bgCol} onChange={handleColorChange} />
              </div>
            )}
          </div>
          <div>
            <p>Content</p>
            <RichText ref={editorRef} bgCol={bgCol} />
          </div>
          <button type="submit" disabled={loading}>
            {loading ? <LoadingSpinner /> : "Create"}
          </button>
        </form>
      </div>
    </div>
  );
};

export default HomePanelsNew;
