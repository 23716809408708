import "./TextInput.scss";

const TextInput = ({
  value,
  setValue,
  type = "text",
  label,
  readonly = false,
  required = false,
  min,
  max,
  step,
}) => (
  <div className="text-input">
    <p>{label}</p>
    <input
      type={type}
      value={value}
      readOnly={readonly}
      required={required}
      min={min}
      max={max}
      step={step}
      onChange={(e) => setValue(e.target.value)}
    />
  </div>
);

export default TextInput;
