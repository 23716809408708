import baseWebsiteRequest from "../../httpRequest/baseWebsiteRequest";
import httpRequest from "../../httpRequest/httpRequest";

class Home {
  static new(name, bgCol, content, specials) {
    return httpRequest("POST", "home/new", {
      name,
      bgCol,
      content,
      specials: specials.join(" "),
      txtCol: "#ffffff",
    });
  }

  static show(id) {
    return httpRequest("GET", `home/${id}`);
  }

  static edit(id, name, bgCol, content, specials) {
    return httpRequest("POST", "home/update", {
      id,
      name,
      bgCol,
      content,
      specials: specials.join(" "),
      txtCol: "#ffffff",
    });
  }

  static delete(id) {
    return httpRequest("POST", "home/delete", { id });
  }

  static getImages() {
    return baseWebsiteRequest("GET", "content/index.php");
  }
}

export default Home;
