import "./TextAreaInput.scss";

const TextAreaInput = ({
  value,
  setValue,
  type = "text",
  label,
  readonly = false,
}) => (
  <div className="text-area-input">
    <p>{label}</p>
    <p className="smol">
      {"<b>"}
      <b>Bold</b>
      {"</b> <i>"}
      <i>Italics</i>
      {"</i>"}
    </p>
    <textarea
      type={type}
      readOnly={readonly}
      onChange={(e) => setValue(e.target.value)}
    >
      {value}
    </textarea>
  </div>
);

export default TextAreaInput;
