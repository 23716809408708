import { faEye } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useContext, useState } from "react";
import Tab from "../../contexts/Tab/Tab";
import Modal from "../Modal/Modal";
import ConfirmationModal from "../Modal/modals/ConfirmationModal/ConfirmationModal";
import FeaturedModal from "../Modal/modals/FeaturedModal/FeaturedModal";
import "./Item.scss";

const Item = ({
  id,
  name,
  price,
  sections,
  sizes,
  imgs,
  displayActions = false,
}) => {
  const { setCurrentTab } = useContext(Tab);
  const [modalOpen, setModalOpen] = useState(false);
  const [confirmationOpen, setConfirmationOpen] = useState(false);
  const [modalImage, setModalImage] = useState();

  const formatPrice = (price) =>
    new Intl.NumberFormat("en-GB", {
      style: "currency",
      currency: "GBP",
    }).format(price);

  const handlePicturesButton = () => {
    setModalOpen(!modalOpen);
    setModalImage(0);
  };

  const handleDeleteButton = () => {
    setConfirmationOpen(true);
  };

  const handleDelete = (e) => {};

  const handleCancel = () => {
    setConfirmationOpen(false);
  };

  return (
    <div className="item">
      {modalOpen && (
        <Modal isOpen={modalOpen} handleClose={handlePicturesButton}>
          <FeaturedModal
            photos={imgs}
            directory={"content/shop"}
            idx={modalImage}
            setIdx={setModalImage}
          />
        </Modal>
      )}
      {confirmationOpen && (
        <Modal isOpen={confirmationOpen} handleClose={handleCancel}>
          <ConfirmationModal
            onConfirm={handleDelete}
            onCancel={handleCancel}
            text={"Are you sure you want to delete this Item?"}
          />
        </Modal>
      )}
      <div className="name">{name}</div>
      <div className="name">{formatPrice(price)}</div>
      <div className="name">{sizes.join(", ")}</div>
      <div className="name">{sections.join(", ")}</div>
      <div className="name">
        {imgs.length}
        <button
          className={"image-view"}
          onClick={handlePicturesButton}
          title="View"
        >
          <FontAwesomeIcon icon={faEye} />
        </button>
      </div>
      {displayActions && (
        <div className="actions">
          <button onClick={() => setCurrentTab(`shop/item/edit/${id}`)}>
            Edit
          </button>
          <button onClick={handleDeleteButton}>Delete</button>
        </div>
      )}
    </div>
  );
};

export default Item;
