import React from "react";
import "./ErrorBoundary.scss";

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false, currentTab: props.currentTab };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true };
  }

  static getDerivedStateFromProps(props, current_state) {
    if (current_state.currentTab !== props.currentTab) {
      return {
        currentTab: props.currentTab,
        hasError: false,
      };
    }
    return null;
  }

  render() {
    if (this.state.hasError) {
      return <div className="error-boundary">{this.state.error}</div>;
    }
    return this.props.children;
  }
}

export default ErrorBoundary;
