import "./Toggle.scss";
import PropTypes from "prop-types";
import { useEffect } from "react";

const Toggle = ({
  label,
  toggled = false,
  toggle,
  optionToggled = "Yes",
  optionOff = "No",
}) => {
  useEffect(() => {
    document.getElementById(`${label}-inner`).dataset.content = toggled
      ? optionToggled
      : optionOff;
  }, [toggled]);

  return (
    <div className="container">
      <div>{label}</div>
      <div className="toggle-switch">
        <input
          type="checkbox"
          className="checkbox"
          name={label}
          checked={toggled}
          id={label}
          onClick={toggle}
        />
        <label className="label" htmlFor={label}>
          <span id={`${label}-inner`} className="inner" />
          <span className="switch" />
        </label>
      </div>
    </div>
  );
};

Toggle.propTypes = {
  label: PropTypes.string,
  toggle: PropTypes.func.isRequired,
  optionToggled: PropTypes.string,
  optionOff: PropTypes.string,
  toggled: PropTypes.bool,
};

export default Toggle;
