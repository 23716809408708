import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";
import "./TagsInput.scss";

const TagsInput = ({ tags, setTags, label }) => {
  const [current, setCurrent] = useState("");
  const handleKey = (e) => {
    if (e.keyCode === 188 || e.keyCode === 32) {
      e.preventDefault();
      e.stopPropagation();
      if (!current) return;
      if (!tags.includes(current)) {
        setCurrent("");
        return setTags([...tags, current]);
      }
      e.target.className = "invalid";
      e.target.setCustomValidity("Tag already exists!");
      e.target.reportValidity();
      setTimeout(() => {
        e.target.setCustomValidity("");
        e.target.className = "";
        e.target.reportValidity();
      }, 2500);
    }
  };

  const removeTag = (idx) => {
    setTags(tags.filter((_, i) => i !== idx));
  };

  return (
    <div className="tags-input">
      <p>{label}</p>
      <div className="tags">
        {tags.map((t, i) => (
          <span className="tag" onClick={() => removeTag(i)}>
            {t} <FontAwesomeIcon icon={faTimes} />
          </span>
        ))}
      </div>
      <br />
      <input
        value={current}
        onChange={(e) => setCurrent(e.target.value)}
        onKeyDown={handleKey}
        placeholder={"Space or comma adds a tag"}
      />
    </div>
  );
};

export default TagsInput;
