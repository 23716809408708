import externalUpload from "../../httpRequest/externalUpload";
import httpRequest from "../../httpRequest/httpRequest";

class Contact {
  static new(title, name, num1, num2, email, pos, img) {
    return httpRequest("POST", "contact/new", {
      title,
      name,
      num1,
      num2,
      email,
      pos,
      img,
    });
  }

  static delete(id) {
    return httpRequest("POST", "contact/delete", { id });
  }

  static update(id, title, name, num1, num2, email, pos, img) {
    return httpRequest("POST", "contact/update", {
      id,
      title,
      name,
      num1,
      num2,
      email,
      pos,
      img: img || null,
    });
  }

  static uploadImage(img) {
    const formData = new FormData();

    formData.append("contact", img, img.name);

    return externalUpload("POST", "contactImageUpload.php", formData);
  }
}

export default Contact;
