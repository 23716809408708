import {
  faEdit,
  faHourglass,
  faLock,
  faTrash,
  faUnlock,
  faWarning,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";
import { useContext } from "react";
import Tab from "../../../../../contexts/Tab/Tab";
import "./UserInfo.scss";

const UserInfo = ({ user, token, refreshData }) => {
  const { setCurrentTab } = useContext(Tab);
  const [lockIcon, setLockIcon] = useState(user.locked ? faUnlock : faLock);
  const [deleteIcon, setDeleteIcon] = useState(faTrash);

  const handleSuccess = (res) => {
    if (res.error || res.errors) {
      setLockIcon(faWarning);
      return setTimeout(
        () => setLockIcon(user.locked ? faUnlock : faLock),
        1500
      );
    }
    setLockIcon(!user.locked ? faUnlock : faLock);
    refreshData();
  };

  const handleLockUnlock = (id) => {
    setLockIcon(faHourglass);
    fetch(`${process.env.REACT_APP_API_URL}/user/lock`, {
      method: "POST",
      headers: {
        Accept: "application/json, text/plain, */*",
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        id: id,
      }),
    })
      .then((res) => res.json())
      .then(handleSuccess);
  };

  const handleDelete = (id) => {
    setDeleteIcon(faHourglass);
    fetch(`${process.env.REACT_APP_API_URL}/user`, {
      method: "DELETE",
      headers: {
        Accept: "application/json, text/plain, */*",
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        id: id,
      }),
    })
      .then((res) => res.json())
      .then((res) => {
        if (res.error || res.errors) {
          setDeleteIcon(faWarning);
          return setTimeout(() => setDeleteIcon(faTrash), 1500);
        }
        refreshData();
      });
  };

  return (
    <div className="user">
      <div className="user-content">
        <h2 className={`user-username ${user.admin ? "sparkle" : ""}`}>
          {user.username}
        </h2>
        <div>{user.email || <i>--No Email--</i>}</div>
      </div>
      <div className="user-actions">
        <div
          onClick={() => setCurrentTab(`admin/user/edit/${user._id}`)}
          title="Edit User"
        >
          <FontAwesomeIcon icon={faEdit} />
        </div>
        <div
          onClick={() => handleLockUnlock(user._id)}
          title={`${user.locked ? "Unlock" : "Lock"} User`}
        >
          <FontAwesomeIcon icon={lockIcon} />
        </div>
        <div onClick={() => handleDelete(user._id)} title="Delete User">
          <FontAwesomeIcon icon={deleteIcon} className={"destructive"} />
        </div>
      </div>
    </div>
  );
};

export default UserInfo;
