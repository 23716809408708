import { useContext, useEffect } from "react";
import { useState } from "react";
import Home from "../../../api/controllers/Home/Home";
import CollapsibleSection from "../../../components/CollapsibleSection/CollapsibleSection";
import LoadingSpinner from "../../../components/LoadingSpinner/LoadingSpinner";
import PanelRow from "../../../components/PanelRow/PanelRow";
import Tab from "../../../contexts/Tab/Tab";
import "./HomePanelsIndex.scss";

const specialClasses = [
  "newmerch",
  "vsocmember",
  "facebook",
  "latest-news",
  "content-max-half",
];

const HomePanelsIndex = () => {
  const [data, setData] = useState();
  const [loading, setLoading] = useState(true);
  const [example, setExample] = useState(specialClasses[0]);
  const [exampleStyles, setExampleStyles] = useState([]);
  const { setCurrentTab } = useContext(Tab);

  useEffect(() => {
    fetch(`${process.env.REACT_APP_API_URL}/home/`)
      .then((response) => response.json())
      .then((response) => setData(response))
      .then(() => setLoading(false));
  }, []);

  useEffect(() => {
    setExampleStyles(renderComputedStyle());
    // eslint-disable-next-line
  }, [document.getElementById(`example-${example}`)]);

  const renderComputedStyle = () => {
    const elem = document.getElementById(`example-${example}`);
    if (!elem) return [];
    const style = window.getComputedStyle(elem);
    const styles = [
      { name: "min-width", value: style.minWidth },
      { name: "max-width", value: style.maxWidth },
      { name: "min-height", value: style.minHeight },
      { name: "max-height", value: style.maxHeight },
      { name: "text-align", value: style.textAlign },
      { name: "margin", value: valsToPt(style.margin) },
      { name: "padding", value: valsToPt(style.padding) },
      { name: "default-background", value: rgbToHex(style.backgroundColor) },
      { name: "default-text-colour", value: rgbToHex(style.color) },
    ];
    return styles;
  };

  const valsToPt = (str) => {
    const split = str.split(" ");
    let x = "";
    split.forEach((val) => {
      x = `${x}${pxToPt(val)}pt `;
    });
    return x;
  };

  function pxToPt(px) {
    const float = parseFloat(px);
    return Math.round(float * (72 / 96));
  }

  function rgbToHex(rgbString) {
    const rgb = rgbString.split("(")[1].split(")")[0].split(",");
    return "#" + toHex(rgb[0]) + toHex(rgb[1]) + toHex(rgb[2]);
  }

  function toHex(n) {
    n = parseInt(n, 10);
    if (isNaN(n)) return "00";
    n = Math.max(0, Math.min(n, 255));
    return (
      "0123456789ABCDEF".charAt((n - (n % 16)) / 16) +
      "0123456789ABCDEF".charAt(n % 16)
    );
  }

  const handleDelete = (id) => {
    setLoading(true);
    Home.delete(id).then(() => {
      setData(data.filter((panel) => panel._id !== id));
      setLoading(false);
    });
  };

  return (
    <div className="home-panels">
      <div>
        <button className="inline">Home Panels</button>
      </div>
      <div className="top-section">
        <h2>Home Page Panels</h2>
        <button onClick={() => setCurrentTab("home-panels/new")}>
          Add new panel
        </button>
        <button onClick={() => setCurrentTab("home-panels/images")}>
          Images
        </button>
      </div>
      <div className="content">
        {loading && (
          <div className="spin">
            <LoadingSpinner size="5x" />
          </div>
        )}
        {!loading && (
          <div className="panels-table">
            <div className="table-headers">
              <div className="name">Name</div>
              <div className="class-colours">Background Colour</div>
              <div className="class-colours">Text Colour</div>
              <div className="class-colours">Special Classes</div>
              <div className="actions"></div>
            </div>
            <div className="table-content">
              {data.map((panel, i) => (
                <PanelRow
                  key={i}
                  panel={panel}
                  handleDelete={() => handleDelete(panel._id)}
                />
              ))}
            </div>
          </div>
        )}
        <CollapsibleSection title={"Special Class Examples"}>
          <div>
            {specialClasses.map((cName) => (
              <>
                <input
                  type={"radio"}
                  onChange={() => setExample(cName)}
                  name={cName}
                  id={cName}
                  value={cName}
                  checked={example === cName}
                />
                <label htmlFor={cName}>{cName}</label>
              </>
            ))}
            <h2>Class Example: {example}</h2>
            <div className={`example ${example}`} id={`example-${example}`}>
              Key Styling:
              <ul>
                {exampleStyles.map((item, idx) => (
                  <li key={idx}>
                    {item.name}: {item.value}
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </CollapsibleSection>
      </div>
    </div>
  );
};

export default HomePanelsIndex;
