import Dropdown from "../../../../components/Dropdown/Dropdown";
import LoadingSpinner from "../../../../components/LoadingSpinner/LoadingSpinner";
import Tab from "../../../../contexts/Tab/Tab";
import { faTimes, faPlus } from "@fortawesome/free-solid-svg-icons";
import { useState, useEffect, useContext } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./FeaturedUpdate.scss";

const FeaturedUpdate = () => {
  const [featured, setFeatured] = useState([]);
  const [items, setItems] = useState([]);
  const [loading, setLoading] = useState(true);
  const { setCurrentTab } = useContext(Tab);
  const [error, setError] = useState("");

  useEffect(() => {
    fetch(`${process.env.REACT_APP_API_URL}/shop/v2`)
      .then((response) => response.json())
      .then((response) => {
        setItems(response.items);
        setFeatured(response.featured);
      })
      .then(() => setLoading(false));
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setLoading(true);
    fetch(`${process.env.REACT_APP_API_URL}/shop/v2/featured`, {
      method: "PUT",
      headers: {
        Accept: "application/json, text/plain, */*",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ items: featured.filter((i) => i.name) }),
    })
      .then((response) => response.json())
      .then((response) => {
        if (response.error) {
          setError(response.error);
        }
      })
      .then(() => setLoading(false));
  };

  const handleFeatured = (idx, value) => {
    setFeatured(featured.map((v, i) => (i === idx ? value : v)));
  };

  const removeRow = (idx) => {
    setFeatured(featured.filter((_, i) => i !== idx));
  };

  const addRow = () => setFeatured([...featured, {}]);

  const clearItem = (idx) =>
    setFeatured(featured.map((v, i) => (i === idx ? {} : v)));

  return (
    <div className="shop-update-featured">
      <div>
        <button className="inline" onClick={() => setCurrentTab("shop")}>
          Shop
        </button>{" "}
        &gt; <button className="inline">Update featured</button>
      </div>
      <h2>Update Featured Products</h2>
      {error && (
        <p
          style={{
            textAlign: "center",
            color: "red",
            fontWeight: "bolder",
            cursor: "pointer",
          }}
          onClick={() => setError({})}
        >
          {error}
        </p>
      )}
      <form onSubmit={handleSubmit}>
        {featured.length === 0 && (
          <div className="featured-row placeholder">
            Click &quot;Add Product&quot; to get started
          </div>
        )}
        {featured.map((featItem, idx) => (
          <div className="featured-row">
            <Dropdown
              key={idx}
              options={items}
              value={featItem.name}
              onSelect={(val) => handleFeatured(idx, val)}
              onDisplay={(val) => val.name}
              placeholder="Select an item"
              onClear={() => clearItem(idx)}
            />
            <button
              className="fr-remove"
              type="button"
              onClick={() => removeRow(idx)}
            >
              <FontAwesomeIcon icon={faTimes} />
            </button>
          </div>
        ))}

        <div className="featured-row">
          <button className="fr-add" type="button" onClick={addRow}>
            <FontAwesomeIcon icon={faPlus} /> Add Product
          </button>
        </div>
        <button type="submit" disabled={loading}>
          {loading ? <LoadingSpinner /> : "Update"}
        </button>
      </form>
    </div>
  );
};

export default FeaturedUpdate;
