import { faCircleInfo } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { forwardRef } from "react";
import "./FileInput.scss";

const FileInput = forwardRef(({ label, multiple = false, info }, ref) => (
  <div className="file-input">
    <p>
      {label}
      {info && (
        <FontAwesomeIcon
          icon={faCircleInfo}
          title={info}
          className="info-hover"
        />
      )}
    </p>
    <input type="file" ref={ref} multiple={multiple} />
  </div>
));

FileInput.displayName = "FileInput";

export default FileInput;
