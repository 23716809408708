import { createRef, useContext, useState } from "react";
import Event from "../../../api/controllers/Event/Event";
import FileInput from "../../../components/FileInput/FileInput";
import LoadingSpinner from "../../../components/LoadingSpinner/LoadingSpinner";
import Message from "../../../components/Message/Message";
import TextInput from "../../../components/TextInput/TextInput";
import Tab from "../../../contexts/Tab/Tab";
import "./EventsNew.scss";

const EventsNew = () => {
  const { setCurrentTab } = useContext(Tab);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState();
  const [name, setName] = useState("");
  const [date, setDate] = useState("");
  const fileRef = createRef();

  const handleSubmit = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setLoading(true);
    const posterToUpload = fileRef.current.files[0];
    if (!posterToUpload) {
      setLoading(false);
      return setError({ error: "PDF cannot be blank" });
    }
    Event.uploadPoster(posterToUpload).then(
      () => {
        Event.new(name, date, posterToUpload.name)
          .then(
            () => setCurrentTab("poster"),
            (err) => setError(err)
          )
          .finally(() => setLoading(false));
      },
      (err) => {
        setError(err);
        setLoading(false);
      }
    );
  };
  return (
    <div className="new-event">
      <div>
        <button className="inline" onClick={() => setCurrentTab("events")}>
          Event Posters
        </button>{" "}
        &gt; <button className="inline">New</button>
      </div>
      <h2>New Event Poster</h2>
      {error && (
        <Message
          type="error"
          message={error.error}
          onClick={() => setError()}
        />
      )}
      <form onSubmit={handleSubmit}>
        <TextInput value={name} setValue={setName} label={"Name"} />
        <TextInput
          value={date}
          setValue={setDate}
          label={"Event Date"}
          type="date"
        />
        <FileInput
          ref={fileRef}
          label={"Select PDF to upload:"}
          info={"Only JPG / jpg files can be uploaded"}
        />
        <button type="submit" disabled={loading}>
          {loading ? <LoadingSpinner /> : "Upload"}
        </button>
      </form>
    </div>
  );
};

export default EventsNew;
