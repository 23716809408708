import { formatDate } from "../../../../utils/formatDate";
import "./ArchiveAlbum.scss";

const ArchiveAlbum = ({ album }) => (
  <div className="photo-album">
    <div className="pa-name">
      {album.name} - {formatDate(album.date)}
    </div>
    <a
      target={"_blank"}
      href={album.url}
      title="Will open in new tab"
      rel="noreferrer"
    >
      {album.url}
    </a>
  </div>
);

export default ArchiveAlbum;
