import "./Message.scss";

const Message = ({ type, message, title, onClick = () => {} }) => {
  const getTitle = () => {
    switch (true) {
      case title:
        return title;
      case type === "error":
        return "Error";
      case type === "warning":
        return "Warning";
      case type === "success":
        return "Success";
      default:
        return "Action Response";
    }
  };

  return (
    <div className={`message ${type}`} onClick={onClick}>
      <h3>{getTitle()}</h3>
      {typeof message === "string" && <p>{message}</p>}
      {typeof message === "object" && (
        <ul>
          {Object.entries(message).map((v) => (
            <li>{v[1].message}</li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default Message;
