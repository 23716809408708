import { useEffect, useState, useContext } from "react";
import Home from "../../../api/controllers/Home/Home";
import LoadingSpinner from "../../../components/LoadingSpinner/LoadingSpinner";
import Tab from "../../../contexts/Tab/Tab";
import "./HomePanelsImages.scss";

const HomePanelsImages = () => {
  const { setCurrentTab } = useContext(Tab);
  const [images, setImages] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    Home.getImages()
      .then((res) => setImages(res))
      .finally(() => setLoading(false));
  }, []);

  return (
    <div className="home-panels">
      <div>
        <button className="inline" onClick={() => setCurrentTab("home-panels")}>
          Home Panels
        </button>{" "}
        &gt; <button className="inline">Images</button>
      </div>
      <div className="top-section">
        <h2>Home Page Images</h2>
      </div>
      <div className="content">
        {loading ? (
          <LoadingSpinner />
        ) : (
          images.map((img) => (
            <a key={img} href={img} target={"_blank"} rel={"noreferrer"}>
              {img}
            </a>
          ))
        )}
      </div>
    </div>
  );
};

export default HomePanelsImages;
