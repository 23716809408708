import { useContext, useState } from "react";
import LoadingSpinner from "../../../components/LoadingSpinner/LoadingSpinner";
import TextInput from "../../../components/TextInput/TextInput";
import Tab from "../../../contexts/Tab/Tab";
import "./NewsletterYearNew.scss";

const NewsletterYearNew = () => {
  const { setCurrentTab } = useContext(Tab);
  const [loading, setLoading] = useState(false);
  const [year, setYear] = useState("");
  const [error, setError] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();
    e.stopPropagation();
    fetch(`${process.env.REACT_APP_API_URL}/newsletter/new/year`, {
      method: "POST",
      headers: {
        Accept: "application/json, text/plain, */*",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        year: year,
      }),
    })
      .then((res) => res.json())
      .then((res) => {
        if (res.message) {
          if (res.message.message) return setError(res.message.message);
          return setError(
            `Failed to create year "${res.message.keyValue.year}": Years must be unique`
          );
        }
        setCurrentTab("newsletters");
      })
      .then(() => setLoading(false));
  };

  return (
    <div className="newsletter-new-year">
      <div>
        <button className="inline" onClick={() => setCurrentTab("newsletters")}>
          Newsletters
        </button>{" "}
        &gt; <button className="inline">New year</button>
      </div>
      <h2>New Newsletter Year</h2>
      {error && (
        <p
          style={{
            textAlign: "center",
            color: "red",
            fontWeight: "bolder",
            cursor: "pointer",
          }}
          onClick={() => setError({})}
        >
          {error}
        </p>
      )}
      <form onSubmit={handleSubmit}>
        <TextInput value={year} setValue={setYear} label={"Year"} />
        <button type="submit" disabled={loading}>
          {loading ? <LoadingSpinner /> : "Create"}
        </button>
      </form>
    </div>
  );
};

export default NewsletterYearNew;
