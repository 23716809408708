import externalUpload from "../../httpRequest/externalUpload";
import httpRequest from "../../httpRequest/httpRequest";

class Event {
  static uploadPoster(img) {
    const formData = new FormData();

    formData.append("poster", img, img.name);

    return externalUpload("POST", "posterUpload.php", formData);
  }

  static new(name, date, image) {
    return httpRequest("POST", "poster/update", {
      name,
      date,
      url: image,
    });
  }
}

export default Event;
