import { useContext, useEffect, useState } from "react";
import ArchiveYear from "../../../components/ArchiveYear/ArchiveYear";
import CollapsibleSection from "../../../components/CollapsibleSection/CollapsibleSection";
import LoadingSpinner from "../../../components/LoadingSpinner/LoadingSpinner";
import Modal from "../../../components/Modal/Modal";
import FeaturedModal from "../../../components/Modal/modals/FeaturedModal/FeaturedModal";
import Tab from "../../../contexts/Tab/Tab";
import "./PhotosIndex.scss";

const PhotosIndex = () => {
  const [rollover, setRollover] = useState({});
  const [archives, setArchives] = useState([]);
  const [rollLoading, setRollLoading] = useState(true);
  const [archiveLoading, setArchiveLoading] = useState(true);
  const [modalOpen, setModalOpen] = useState(false);
  const [modalImage, setModalImage] = useState();
  const { setCurrentTab } = useContext(Tab);

  useEffect(() => {
    fetch(`${process.env.REACT_APP_API_URL}/photo/roll/`)
      .then((response) => response.json())
      .then((response) => setRollover(response[0] || []))
      .then(() => setRollLoading(false));
    fetch(`${process.env.REACT_APP_API_URL}/photo/archive/`)
      .then((response) => response.json())
      .then((response) => setArchives(response))
      .then(() => setArchiveLoading(false));
  }, []);

  const FeaturedButton = (
    <button
      onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();
        setCurrentTab("photos/featured/update");
      }}
    >
      Update featured
    </button>
  );

  const ArchiveButton = (
    <button
      onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();
        setCurrentTab("photos/year/new");
      }}
    >
      Add new Year
    </button>
  );

  const handlePicturesButton = () => {
    setModalOpen(!modalOpen);
    setModalImage(0);
  };

  return (
    <div className="photos">
      {modalOpen && (
        <Modal isOpen={modalOpen} handleClose={handlePicturesButton}>
          <FeaturedModal
            photos={rollover.photos}
            idx={modalImage}
            directory="photostore"
            setIdx={setModalImage}
          />
        </Modal>
      )}
      <div>
        <button className="inline">Photos</button>
      </div>
      <div className="top-section">
        <h2>Photos</h2>
      </div>
      <div className="content">
        {(rollLoading || archiveLoading) && (
          <div className="spin">
            <LoadingSpinner size="5x" />
          </div>
        )}
        {!rollLoading && (
          <CollapsibleSection
            title={"Highlighted Album"}
            extraComponent={FeaturedButton}
          >
            <div className="featured-container">
              <p className="featured-text">
                <b>Album Title:</b> {rollover.a_name}
              </p>
              <p className="featured-text">
                <b>Album Link: </b>
                <a
                  target={"_blank"}
                  href={rollover.a_url}
                  rel="noreferrer"
                  title="Will open in new tab"
                >
                  {rollover.a_url}
                </a>
              </p>
              <p className="featured-text">
                <b>Amount of Images:</b> {rollover.photos?.length}
              </p>
              <div className="featured-button">
                <button onClick={handlePicturesButton}>Show Images</button>
              </div>
            </div>
          </CollapsibleSection>
        )}
        {!archiveLoading && (
          <CollapsibleSection title={"Archive"} extraComponent={ArchiveButton}>
            {archives.map((archive) => (
              <ArchiveYear key={archive._id} year={archive} />
            ))}
          </CollapsibleSection>
        )}
      </div>
    </div>
  );
};

export default PhotosIndex;
