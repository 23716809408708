import { createRef, useContext, useState } from "react";
import FileInput from "../../../components/FileInput/FileInput";
import LoadingSpinner from "../../../components/LoadingSpinner/LoadingSpinner";
import Message from "../../../components/Message/Message";
import TextInput from "../../../components/TextInput/TextInput";
import Tab from "../../../contexts/Tab/Tab";
import "./NewsletterUpload.scss";

const NewsletterUpload = ({ month, year }) => {
  const { setCurrentTab } = useContext(Tab);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState();
  const fileRef = createRef();

  const handleSubmit = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setLoading(true);
    const pdfToUpload = fileRef.current.files[0];
    if (!pdfToUpload) {
      setLoading(false);
      return setError({ error: "PDF cannot be blank" });
    }
    setLoading(false);
  };

  return (
    <div className="newsletter-upload">
      <div>
        <button className="inline" onClick={() => setCurrentTab("newsletters")}>
          Newsletters
        </button>{" "}
        &gt;{" "}
        <button className="inline">
          Upload: {month} {year}
        </button>
      </div>
      <h2>Newsletter Upload</h2>
      <Message message={"Currently not fully implemented"} title={"Notice"} />
      {error && (
        <Message
          type="error"
          message={error.error}
          onClick={() => setError()}
        />
      )}
      <form onSubmit={handleSubmit}>
        <TextInput value={year} label={"Year"} readonly />
        <TextInput value={month} label={"Month"} readonly />
        <FileInput ref={fileRef} label={"Select PDF to upload:"} />
        <button type="submit" disabled={loading}>
          {loading ? <LoadingSpinner /> : "Upload"}
        </button>
      </form>
    </div>
  );
};

export default NewsletterUpload;
