import { useContext, useEffect, useState } from "react";
import CollapsibleSection from "../../../components/CollapsibleSection/CollapsibleSection";
import Item from "../../../components/Item/Item";
import LoadingSpinner from "../../../components/LoadingSpinner/LoadingSpinner";
import Tab from "../../../contexts/Tab/Tab";
import "./ShopIndex.scss";

const ShopIndex = () => {
  const [featured, setFeatured] = useState([]);
  const [items, setItems] = useState([]);
  const [loading, setLoading] = useState(true);
  const { setCurrentTab } = useContext(Tab);

  useEffect(() => {
    fetch(`${process.env.REACT_APP_API_URL}/shop/v2`)
      .then((response) => response.json())
      .then((response) => {
        setItems(response.items || []);
        setFeatured(response.featured || []);
      })
      .then(() => setLoading(false))
      .catch((err) => setLoading(false));
  }, []);

  const FeaturedButton = (
    <button
      onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();
        setCurrentTab("shop/featured/update");
      }}
    >
      Update featured
    </button>
  );

  const ArchiveButton = (
    <button
      onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();
        setCurrentTab("shop/item/new");
      }}
    >
      Add product
    </button>
  );

  return (
    <div className="photos">
      <div>
        <button className="inline">Shop</button>
      </div>
      <div className="top-section">
        <h2>Shop</h2>
      </div>
      <div className="content">
        {loading && (
          <div className="spin">
            <LoadingSpinner size="5x" />
          </div>
        )}
        {!loading && (
          <>
            <CollapsibleSection
              title={"Featured Products"}
              extraComponent={FeaturedButton}
            >
              <div className="item-table">
                <div className="table-headers">
                  <div className="name">Name</div>
                  <div className="name">Price</div>
                  <div className="name">Sizes</div>
                  <div className="name">Sections</div>
                  <div className="name">Images</div>
                </div>
                <div className="table-content">
                  {featured.map(
                    ({ _id, name, price, sections, sizes, imgs }) => (
                      <Item
                        key={_id}
                        id={_id}
                        name={name}
                        price={price}
                        sections={sections}
                        sizes={sizes}
                        imgs={imgs}
                      />
                    )
                  )}
                </div>
              </div>
            </CollapsibleSection>

            <CollapsibleSection
              title={"All Items"}
              extraComponent={ArchiveButton}
            >
              <div className="item-table">
                <div className="table-headers">
                  <div className="name">Name</div>
                  <div className="name">Price</div>
                  <div className="name">Sizes</div>
                  <div className="name">Sections</div>
                  <div className="name">Images</div>
                  <div className="actions"></div>
                </div>
                <div className="table-content">
                  {items.map(({ _id, name, price, sections, sizes, imgs }) => (
                    <Item
                      key={_id}
                      id={_id}
                      name={name}
                      price={price}
                      sections={sections}
                      sizes={sizes}
                      imgs={imgs}
                      displayActions
                    />
                  ))}
                </div>
              </div>
            </CollapsibleSection>
          </>
        )}
      </div>
    </div>
  );
};

export default ShopIndex;
