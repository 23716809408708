import { createRef, useContext, useState } from "react";
import FileInput from "../../../components/FileInput/FileInput";
import LoadingSpinner from "../../../components/LoadingSpinner/LoadingSpinner";
import Message from "../../../components/Message/Message";
import TextInput from "../../../components/TextInput/TextInput";
import Tab from "../../../contexts/Tab/Tab";
import "./PhotosUpdateFeatured.scss";

const PhotosUpdateFeatured = () => {
  const { setCurrentTab } = useContext(Tab);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState();
  const [name, setName] = useState("");
  const [url, setUrl] = useState("");
  const fileRef = createRef();

  const handleSubmit = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setLoading(true);
    const pdfToUpload = fileRef.current.files[0];
    if (!pdfToUpload) {
      setLoading(false);
      return setError({ error: "PDF cannot be blank" });
    }
    setLoading(false);
  };
  return (
    <div className="update-featured">
      <div>
        <button className="inline" onClick={() => setCurrentTab("photos")}>
          Photos
        </button>{" "}
        &gt; <button className="inline">Update featured</button>
      </div>
      <h2>Update Featured Album</h2>
      <Message message={"Currently not fully implemented"} title={"Notice"} />
      {error && (
        <Message
          type="error"
          message={error.error}
          onClick={() => setError()}
        />
      )}
      <form onSubmit={handleSubmit}>
        <TextInput value={name} setValue={setName} label={"Name"} />
        <TextInput value={url} setValue={setUrl} label={"Album Link"} />
        <FileInput
          ref={fileRef}
          label={"Select Highlight Images:"}
          info={
            "Blank no change\nOverwrites all\n[CTRL + click Selects multiple]"
          }
          multiple
        />
        <button type="submit" disabled={loading}>
          {loading ? <LoadingSpinner /> : "Update"}
        </button>
      </form>
    </div>
  );
};

export default PhotosUpdateFeatured;
