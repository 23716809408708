import { useContext } from "react";
import Tab from "../../contexts/Tab/Tab";
import "./PanelRow.scss";

const PanelRow = ({ panel, handleDelete }) => {
  const { setCurrentTab } = useContext(Tab);

  return (
    <div className="home-panel-row">
      <div className="name">{panel.name}</div>
      <div className="class-colours">
        {panel.bgCol}
        <span className="square" style={{ backgroundColor: panel.bgCol }} />
      </div>
      <div className="class-colours">
        {panel.txtCol}
        <span className="square" style={{ backgroundColor: panel.txtCol }} />
      </div>
      <div className="class-colours">{panel.specials}</div>
      <div className="actions">
        <button onClick={() => setCurrentTab(`home-panels/edit/${panel._id}`)}>
          edit
        </button>
        <button onClick={handleDelete}>delete</button>
      </div>
    </div>
  );
};

export default PanelRow;
