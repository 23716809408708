import { useEffect } from "react";
import { useState } from "react";
import { useContext } from "react";
import RollHonour from "../../../api/controllers/RollHonour/RollHonour";
import LoadingSpinner from "../../../components/LoadingSpinner/LoadingSpinner";
import Tab from "../../../contexts/Tab/Tab";
import "./RollHonourIndex.scss";
import RollSection from "./RollSection/RollSection";

const RollHonourIndex = () => {
  const { setCurrentTab } = useContext(Tab);
  const [roll, setRoll] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(true);
    RollHonour.all(true)
      .then((res) => setRoll(formatForDisplay(res)))
      .finally(() => setLoading(false));
  }, []);

  const formatForDisplay = (arrayOfItems) => {
    return arrayOfItems.reduce((r, a) => {
      r[a[0]] = r[a[0]] || [];
      r[a[0]].push(a);
      return r;
    }, Object.create(null));
  };

  return (
    <div className="roll-honour">
      <div>
        <button className="inline">Roll of Honour</button>
      </div>
      <div className="top-section">
        <h2>Roll of Honour</h2>
        <button onClick={() => setCurrentTab("roll-of-honour/item/new")}>
          Add new Item
        </button>
        <button onClick={() => setCurrentTab("roll-of-honour/formatted")}>
          View Formatted Timeline
        </button>
      </div>
      <div className="content">
        {loading && (
          <div className="spin">
            <LoadingSpinner size="5x" />
          </div>
        )}
        {Object.keys(roll).map((key) => {
          const items = roll[key];
          return <RollSection title={key} items={items} />;
        })}
      </div>
    </div>
  );
};

RollHonourIndex.propTypes = {};

export default RollHonourIndex;
