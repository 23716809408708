import { faPlus, faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect } from "react";
import { createRef, useContext, useState } from "react";
import Shop from "../../../../api/controllers/Shop/Shop";
import FileInput from "../../../../components/FileInput/FileInput";
import LoadingSpinner from "../../../../components/LoadingSpinner/LoadingSpinner";
import TagsInput from "../../../../components/TagsInput/TagsInput";
import TextAreaInput from "../../../../components/TextAreaInput/TextAreaInput";
import TextInput from "../../../../components/TextInput/TextInput";
import Tab from "../../../../contexts/Tab/Tab";
import "./ShopItemEdit.scss";
import PropTypes from "prop-types";

const ShopItemEdit = ({ id }) => {
  const { setCurrentTab } = useContext(Tab);
  const [dataLoading, setDataLoading] = useState(true);
  const [loading, setLoading] = useState(false);
  const [name, setName] = useState("");
  const [price, setPrice] = useState("");
  const [description, setDescription] = useState("");
  const [error, setError] = useState("");
  const [sizes, setSizes] = useState([]);
  const [sections, setSections] = useState([]);
  const [imageNames, setImageNames] = useState([]);
  const fileRef = createRef();

  useEffect(() => {
    fetch(`${process.env.REACT_APP_API_URL}/shop/v2/item/${id}`)
      .then((response) => response.json())
      .then((response) => {
        setName(response.name);
        setDescription(response.description);
        setPrice(response.price);
        setSections(response.sections);
        setSizes(response.sizes);
        setImageNames(response.imgs);
      })
      .then(() => setDataLoading(false));
  }, [id]);

  const handleSubmit = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setLoading(true);
    const images = fileRef.current.files;
    if (images.length === 0) {
      Shop.editItem(id, name, imageNames, price, sizes, sections, description)
        .then(
          () => setCurrentTab("shop"),
          (err) => setError(err)
        )
        .finally(() => setLoading(false));
    } else {
      Shop.uploadImages(images).then(
        () => {
          Shop.editItem(
            id,
            name,
            images.map((image) => image.name),
            price,
            sizes,
            sections,
            description
          )
            .then(
              () => setCurrentTab("shop"),
              (err) => setError(err)
            )
            .finally(() => setLoading(false));
        },
        (err) => {
          setError(err);
          setLoading(false);
        }
      );
    }
  };

  const handleSizeChange = (idx, value) => {
    setSizes(sizes.map((v, i) => (i === idx ? value : v)));
  };

  const removeRow = (idx) => {
    setSizes(sizes.filter((_, i) => i !== idx));
  };

  const addRow = () => setSizes([...sizes, ""]);

  return (
    <div className="shop-edit-item">
      <div>
        <button className="inline" onClick={() => setCurrentTab("shop")}>
          Shop
        </button>{" "}
        &gt; <button className="inline">Update Item: {id}</button>
      </div>
      <h2>Update Item</h2>
      {error && (
        <p
          style={{
            textAlign: "center",
            color: "red",
            fontWeight: "bolder",
            cursor: "pointer",
          }}
          onClick={() => setError({})}
        >
          {error}
        </p>
      )}
      {dataLoading && <LoadingSpinner />}
      {!dataLoading && (
        <form onSubmit={handleSubmit}>
          <TextInput value={name} setValue={setName} label={"Name"} />
          <TextInput
            type={"number"}
            value={price}
            setValue={setPrice}
            label={"Price"}
            min={0}
            step={0.01}
          />
          <p>Sizes / Types</p>
          {/* TODO: Change to reusable component */}
          {sizes.map((size, idx) => (
            <div key={size} className="featured-row">
              <TextInput
                key={idx}
                value={size}
                setValue={(val) => handleSizeChange(idx, val)}
              />
              <button
                className="fr-remove"
                type="button"
                onClick={() => removeRow(idx)}
              >
                <FontAwesomeIcon icon={faTimes} />
              </button>
            </div>
          ))}
          <div className="featured-row">
            <button className="fr-add" type="button" onClick={addRow}>
              <FontAwesomeIcon icon={faPlus} /> Add Row
            </button>
          </div>
          <TextAreaInput
            value={description}
            setValue={setDescription}
            label={"Description"}
          />
          <TagsInput tags={sections} setTags={setSections} label={"Sections"} />
          <FileInput
            ref={fileRef}
            label={"Select Images:"}
            info={
              "Blank no change\nOverwrites all\n[CTRL + click Selects multiple]"
            }
            multiple
          />
          <button type="submit" disabled={loading}>
            {loading ? <LoadingSpinner /> : "Update"}
          </button>
        </form>
      )}
    </div>
  );
};

ShopItemEdit.propTypes = {
  id: PropTypes.string,
};

export default ShopItemEdit;
