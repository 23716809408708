import { useContext } from "react";
import ErrorBoundary from "../../components/ErrorBoundary/ErrorBoundary";
import Tab from "../../contexts/Tab/Tab";
import AdminEditUser from "../../pages/Admin/AdminEditUser/AdminEditUser";
import AdminIndex from "../../pages/Admin/AdminIndex/AdminIndex";
import AdminNewUser from "../../pages/Admin/AdminNewUser/AdminNewUser";
import ContactsEdit from "../../pages/Contacts/ContactsEdit/ContactsEdit";
import ContactsIndex from "../../pages/Contacts/ContactsIndex/ContactsIndex";
import ContactsNew from "../../pages/Contacts/ContactsNew/ContactsNew";
import EventsIndex from "../../pages/Events/EventsIndex/EventsIndex";
import EventsNew from "../../pages/Events/EventsNew/EventsNew";
import HomePanelsIndex from "../../pages/HomePanels/HomePanelsIndex/HomePanelsIndex";
import NewsletterUpload from "../../pages/Newsletter/NewletterUpload/NewsletterUpload";
import NewsletterIndex from "../../pages/Newsletter/NewsletterIndex/NewsletterIndex";
import NewsletterYearNew from "../../pages/Newsletter/NewsletterYearNew/NewsletterYearNew";
import NotFound from "../../pages/NotFound/NotFound";
import PhotosIndex from "../../pages/Photos/PhotosIndex/PhotosIndex";
import PhotosNewAlbum from "../../pages/Photos/PhotosNewAlbum/PhotosNewAlbum";
import PhotosNewYear from "../../pages/Photos/PhotosNewYear/PhotosNewYear";
import PhotosUpdateFeatured from "../../pages/Photos/PhotosUpdateFeatured/PhotosUpdateFeatured";
import RollHonourEdit from "../../pages/RollHonour/RollHonourEdit/RollHonourEdit";
import RollHonourFormatted from "../../pages/RollHonour/RollHonourFormatted/RolllHonourFormatted";
import RollHonourIndex from "../../pages/RollHonour/RollHonourIndex/RollHonourIndex";
import RollHonourNew from "../../pages/RollHonour/RollHonourNew/RollHonourNew";
import SettingsIndex from "../../pages/Settings/SettingsIndex/SettingsIndex";
import FeaturedUpdate from "../../pages/Shop/Featured/FeaturedUpdate/FeaturedUpdate";
import ShopIndex from "../../pages/Shop/ShopIndex/ShopIndex";
import ShopItemEdit from "../../pages/Shop/ShopItem/ShopItemEdit/ShopItemEdit";
import ShopItemNew from "../../pages/Shop/ShopItem/ShopItemNew/ShopItemNew";
import PropTypes from "prop-types";
import "./Content.scss";
import HomePanelsNew from "../../pages/HomePanels/HomePanelsNew/HomePanelsNew";
import HomePanelsEdit from "../../pages/HomePanels/HomePanelsEdit/HomePanelsEdit";
import HomePanelsImages from "../../pages/HomePanels/HomePanelsImages/HomePanelsImages";

const Content = ({ user }) => {
  const { currentTab } = useContext(Tab);

  const adminContent = (tab) => {
    switch (true) {
      case tab === "admin":
        return <AdminIndex />;
      case tab === "admin/user/new":
        return <AdminNewUser />;
      case /^admin\/user\/edit\/[a-zA-Z0-9]+$/.test(tab):
        return <AdminEditUser id={tab.split("/")[3]} />;
      default:
        return <NotFound />;
    }
  };

  const getContent = (tab) => {
    switch (true) {
      case tab === "live-site" || tab === "":
        return (
          <iframe
            src="https://lostriders.co.uk/"
            className="content-frame"
            title="lostriders-main"
          />
        );
      case tab === "dev-site":
        return (
          <iframe
            src="https://omnicpie.github.io/lostriders/"
            className="content-frame"
            title="lostriders-dev"
          />
        );
      case tab === "settings":
        return <SettingsIndex />;
      case tab === "newsletters":
        return <NewsletterIndex />;
      case tab === "newsletters/year/new":
        return <NewsletterYearNew />;
      case /^newsletters\/upload\/[a-zA-Z0-9]+\/[a-zA-Z0-9]+$/.test(tab): {
        const items = tab.split("/");
        return <NewsletterUpload month={items[2]} year={items[3]} />;
      }
      case tab === "events":
        return <EventsIndex />;
      case tab === "events/new":
        return <EventsNew />;
      case tab === "contacts":
        return <ContactsIndex />;
      case tab === "contacts/new":
        return <ContactsNew />;
      case /^contacts\/edit\/[a-zA-Z0-9]+$/.test(tab): {
        const items = tab.split("/");
        return <ContactsEdit id={items[2]} />;
      }
      case tab === "photos":
        return <PhotosIndex />;
      case /^photos\/album\/new\/[0-9]+$/.test(tab): {
        const items = tab.split("/");
        return <PhotosNewAlbum year={items[3]} />;
      }
      case tab === "photos/year/new":
        return <PhotosNewYear />;
      case tab === "photos/featured/update":
        return <PhotosUpdateFeatured />;
      case tab === "shop":
        return <ShopIndex />;
      case tab === "shop/item/new":
        return <ShopItemNew />;
      case /^shop\/item\/edit\/[a-zA-Z0-9]+$/.test(tab): {
        const items = tab.split("/");
        return <ShopItemEdit id={items[3]} />;
      }
      case tab === "shop/featured/update":
        return <FeaturedUpdate />;
      case tab === "home-panels":
        return <HomePanelsIndex />;
      case tab === "home-panels/new":
        return <HomePanelsNew />;
      case tab === "home-panels/images":
        return <HomePanelsImages />;
      case /^home-panels\/edit\/[a-zA-Z0-9]+$/.test(tab):
        return <HomePanelsEdit id={tab.split("/")[2]} />;
      case tab === "roll-of-honour":
        return <RollHonourIndex />;
      case tab === "roll-of-honour/formatted":
        return <RollHonourFormatted />;
      case tab === "roll-of-honour/item/new":
        return <RollHonourNew />;
      case /^roll-of-honour\/item\/edit\/[a-zA-Z0-9]+$/.test(tab): {
        const items = tab.split("/");
        return <RollHonourEdit id={items[3]} />;
      }
      case tab.startsWith("admin") && user.admin:
        return adminContent(tab);
      default:
        return <NotFound />;
    }
  };

  return (
    <ErrorBoundary currentTab={currentTab}>
      {getContent(currentTab)}
    </ErrorBoundary>
  );
};

Content.propTypes = {
  user: PropTypes.object,
};

export default Content;
